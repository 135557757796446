import React, { useState, useEffect, forwardRef } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";

import { useHistory, useLocation } from "react-router";
import {
  getLocations,
  getRadiologistForDropdown,
  getReferrersForDropdownOptions,
  getReportsModality,
  getSearchReportText,
} from "../../../actions/radminUploads";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import {
  CloseSign,
  Image,
  SelectedItem,
  SelectedItemContainer,
} from "./SearchReportText";
import close from "../../../assets/cancel-outline-chip.svg";
import moment from "moment";
import { isArray } from "lodash";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import DatePicker from "react-datepicker";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  width: 505px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
`;

export const Card = styled(Flex)`
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  media (max-width: 657px) {
    margin-left: 78px;
  }
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
  margin-bottom: 14px;
`;

export const SettingsContainer = styled.div`
  position: relative;
  z-index: 1;
  top: 50px;
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : "0 0 20px 0")};
  border-radius: 8px;
  margin-right: ${(props) => (props.marginRight === "true" ? "45%" : "")};
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const FilterMenuTitle = styled.div`
  margin-bottom: 0px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #0c2945;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  border: ${(props) => props.border || 0};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => props.color};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary }) => ( isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF")};
  }
`;

const Label = styled.label`
  font-size: 9.2px;
  color: #828282;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #000000;
`;
const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "10px"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 3% !important;
  background-clip: content-box;
  cursor: pointer;
`;
const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
`;

const DateFilterContainer = styled(Flex)`
  margin-bottom: 0px;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

const TimeframeContainer = styled(Flex)`
  width: 80%;
  flex-direction: column;
  gap: 8px;
`;

const Timeframe = styled.p`
  margin: 0;
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.4px;
  letter-spacing: 0.2px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const DatePickerContainer = styled(Flex)`
  width: 227px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const DatePickerButton = styled(Flex)`
  padding: 0px 10px;
  height: 43.5px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
  font-weight: 400;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

export const DEFAULT_VALUES = {
  radiologist: "",
  referrers: "",
  message_sent: "",
  location: "",
  report_modality: "",
  exam_code: "",
};

export default function debounce(func, timeout = 300) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ margin: "0 5px", verticalAlign: "middle" }}
        />{" "}
        <label style={{ margin: "0" }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const Filters = (props) => {
  const {
    setPage,
    title,
    exclude,
    setSelectedFilters,
    selectedFilters,
    showModal,
    onClose,
    selectedOperators,
    setSelectedOperators,
    filterValuesForRequest = {},
  } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const referrers = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownReferrers.data
  );
  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );

  const [selectedValues, setSelectedValues] = useState(selectedFilters);
  const [selectedNegatedOperators, setSelectedNegatedOperators] = useState(selectedOperators);

  const [referrerPage, setReferrerPage] = useState(1);
  const [query, setQuery] = useState("");
  const [referrerList, setReferrerList] = useState([]);
  const referrerOptionsList = referrerList?.length
    ? referrerList
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "referrers",
        }))
        .filter((item) => item.value)
    : [];

  const location = useLocation();

  const radiologistOptions = radiologists?.length
    ? radiologists
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "radiologist",
        }))
        .filter((item) => item.value)
    : [];
  const referrerOptions = referrers?.length
    ? referrers
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "referrers",
        }))
        .filter((item) => item.value)
    : [];

  const messageSentOptions = [
    { value: "true", label: "true", category: "message_sent" },
    { value: "false", label: "false", category: "message_sent" },
  ];

  const [locationOptions, setLocationOptions] = useState();
  const [nextLocationPage, setNextLocationPage] = useState();
  const [loadingMoreLocations, setLoadingMoreLocations] = useState(false);
  const [modalityReportOptions, setModalityReportOptions] = useState([]);

  const history = useHistory();

  const DatePickerInput = forwardRef(({ onClick, startDate, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };
    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {startDate && endDate
            ? `${formatDate(startDate)} - ${formatDate(endDate)}`
            : "Select date range"}
        </DateContainer>
        {(!startDate || !endDate) && <CalendarIcon />}
        {startDate && endDate && (
          <DateClearButton
            onClick={() => {
              setSelectedValues((prev) => ({ ...prev, startDate: null }));
              setSelectedValues((prev) => ({ ...prev, endDate: null }));
            }}
          >
            <RemoveRecoIcon style={{ height: "13px" }} />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start, end] = dates;
      setSelectedValues((prev) => ({
        ...prev,
        exam_date: [
          {
            label: `${moment(start).format("YYYY-MM-DD")} - ${moment(
              end
            ).format("YYYY-MM-DD")}`,
            value: `${moment(start).format("YYYY-MM-DD")} - ${moment(
              end
            ).format("YYYY-MM-DD")}`,
            category: "exam_date",
            startDate: start,
            endDate: end,
          },
        ],
      }));
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const mergedFilters = {
      radiologist:
        selectedValues["radiologist"] || filterValuesForRequest["radiologist"],
      referrers:
        selectedValues["referrers"] || filterValuesForRequest["referrers"],
      message_sent:
        selectedValues["message_sent"] ||
        filterValuesForRequest["message_sent"],
      location:
        selectedValues["location"] || filterValuesForRequest["location"],
      report_modality:
        selectedValues["report_modality"] ||
        filterValuesForRequest["report_modality"],
      exam_code:
        selectedValues["exam_code"] || filterValuesForRequest["exam_code"],
      exam_date:
        selectedValues["exam_date"] || filterValuesForRequest["exam_date"],
      modalityNegation: selectedNegatedOperators.reportModalityOperator,
      examCodeNegation: selectedNegatedOperators.examCodeOperator,
    };

    setSelectedFilters(mergedFilters);
    setSelectedOperators(selectedNegatedOperators)

    let params = new URLSearchParams(history.location.search);
    params.delete("message_sent");
    params.delete("radiologist_id");
    params.delete("referring_physician_id");
    params.delete("location_address");
    params.delete("modality");
    params.delete("modality!");
    params.delete("exam_code");
    params.delete("exam_code!");
    params.delete("exam_date_gte");
    params.delete("exam_date_lte");

    if (mergedFilters.exam_date?.length > 0) {
      mergedFilters.exam_date.forEach((itm) => {
        params.append(
          "exam_date_gte",
          moment(itm.startDate).format("YYYY-MM-DD")
        );
        params.append(
          "exam_date_lte",
          moment(itm.endDate).format("YYYY-MM-DD")
        );
      });
    }

    if (mergedFilters.message_sent?.length > 0) {
      mergedFilters.message_sent.forEach((itm) => {
        params.append("message_sent", itm.value);
      });
    }

    if (mergedFilters.radiologist?.length > 0) {
      mergedFilters.radiologist.forEach((itm) => {
        params.append("radiologist_id", itm.value);
      });
    }

    if (mergedFilters.referrers?.length > 0) {
      mergedFilters.referrers.forEach((itm) => {
        params.append("referring_physician_id", itm.value);
      });
    }

    if (mergedFilters.location?.length > 0) {
      mergedFilters.location.forEach((itm) => {
        params.append("location_address", itm.value);
      });
    }
    if (mergedFilters.report_modality?.length > 0) {
      mergedFilters.report_modality.forEach((itm) => {
        if (mergedFilters?.modalityNegation?.value === "isNotEqual") {
          params.append("modality!", itm.value);
        } else {
          params.append("modality", itm.value);
        }
      });
    }
    if (mergedFilters.exam_code?.length > 0) {
      mergedFilters.exam_code.forEach((itm) => {
        if (mergedFilters?.examCodeNegation?.value === "isNotEqual") {
          params.append("exam_code!", itm.value);
        } else {
          params.append("exam_code", itm.value);
        }
      });
    }

    history.replace({
      search: params.toString(),
    });

    dispatch(getSearchReportText(1, title, exclude, mergedFilters));

    setPage(1);
    onClose();
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const RadioOption = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  useEffect(()=>{
    dispatch(getRadiologistForDropdown());
  },[])

  const onInputChange = debounce(async (inputValue) => {
    if (inputValue) {
      setLoading(true);
      setReferrerList([]);
      setQuery(inputValue);
      setReferrerPage(1);
      const res = await dispatch(
        getReferrersForDropdownOptions(`?last_name__icontains=${inputValue}`)
      );
      if (res) {
        setReferrerList(res?.results);
        if (res?.next) {
          setReferrerPage(2);
        }
      }
      setLoading(false);
    } else {
      setReferrerList([]);
      setReferrerPage(1);
    }
  }, 1000);

  const loadMoreOptions = async () => {
    if (referrerPage > 1 && referrerOptionsList?.length >= 10) {
      setLoading(true);
      const res = await dispatch(
        getReferrersForDropdownOptions(
          `?last_name__icontains=${query}`,
          referrerPage
        )
      );
      setReferrerList((prev) => [...prev, ...res?.results]);
      if (res.next) {
        setReferrerPage((prev) => prev + 1);
      } else {
        setReferrerPage(1);
      }
      setLoading(false);
    }
  };

  const loadMoreLocationOptions = async () => {
    if (nextLocationPage) {
      setLoadingMoreLocations(true);
      dispatch(getLocations(nextLocationPage)).then((res) => {
        setLoadingMoreLocations(false);
        setLocationOptions((prev) => [
          ...prev,
          ...res.results.map((itm) => ({
            category: "location",
            label: itm.location_address.replace(/\^/g, ","),
            value: itm.location_address,
          })),
        ]);
        if (res.next) {
          setNextLocationPage((prev) => prev + 1);
        } else {
          setNextLocationPage();
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getLocations()).then((res) => {
      if (res.next) {
        setNextLocationPage(2);
      }
      setLocationOptions(
        res.results.map((itm) => ({
          category: "location",
          label: itm.location_address.replace(/\^/g, ","),
          value: itm.location_address,
        }))
      );
    });
  }, []);

  useEffect(() => {
    dispatch(getReportsModality())
      .then((res) =>
        setModalityReportOptions(
          res.map((itm) => ({
            label: itm.report__modality,
            value: itm.report__modality,
            category: "report_modality",
          }))
        )
      )
      .catch((err) => console.log(err));
  }, []);

  const colourStyles = {
    placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        fontSize: "14px",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isSelected ? "#D9ECFF" : "white",
        display: "flex",
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const [validationError, setValidationError] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event?.target?.value?.split(",");
    setSelectedValues({
      ...selectedValues,
      exam_code: inputValue.map((itm) => ({
        value: itm,
        category: "exam_code",
        label: "Exam Code",
      })),
    });

    const codes = inputValue.map((code) => code.trim());

    let error = "";

    let hasInvalidLength = false;
    let hasInvalidSeparator = false;

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      if (code && !code.match(/^\w{5}$/)) {
        hasInvalidLength = true;
      }
    }

    if (codes.some((code) => code.includes(" "))) {
      hasInvalidSeparator = true;
    }

    if (hasInvalidLength && hasInvalidSeparator) {
      error =
        "<span>Exam codes must be five digits long.</span><br/><span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    } else if (hasInvalidLength) {
      error = "<span>Exam codes must be five digits long.</span>";
    } else if (hasInvalidSeparator) {
      error =
        "<span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>";
    }

    setValidationError(error);
  };

  const closeSelected = (category, selectedItem) => {
    const filters = selectedValues[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedValues,
      [category]: filters.length > 0 ? filters : [],
      modalityNegation: selectedNegatedOperators.reportModalityOperator,
      examCodeNegation: selectedNegatedOperators.examCodeOperator,
    };
    // setSelectedFilters(newFilters);
    setSelectedValues(newFilters);
  };

  return (
    <Modal showModal={showModal}>
      <ModalContentContainer>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <FilterMenuTitle>Filters </FilterMenuTitle>
            <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
          </div>

          <div style={{ width: "100%" }}>
            <FormContainer>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <Row>
                    <SelectContainer>
                      <SelectDropdownTitle>Modality</SelectDropdownTitle>
                      {
                        <div>
                          <SelectContainer
                            style={{ marginBottom: "6px" }}
                            key={`item_1`}
                          >
                            <RadioGroup>
                              <Label>
                                <RadioBtn
                                  type="radio"
                                  value={
                                    selectedNegatedOperators["reportModalityOperator"]
                                  }
                                  checked={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "none"
                                  }
                                  onChange={(value) =>
                                    setSelectedNegatedOperators({
                                      ...selectedNegatedOperators,
                                      reportModalityOperator: {
                                        value: "none",
                                        label: "none",
                                        category: "report_modality",
                                      },
                                    })
                                  }
                                  borderColor={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "none"
                                      ? "#00AEEF"
                                      : "#D4D4D4"
                                  }
                                  backgroundColor={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "none"
                                      ? "#00AEEF"
                                      : "#FFF"
                                  }
                                />
                                include
                              </Label>
                              <Label>
                                <RadioBtn
                                  type="radio"
                                  value={
                                    selectedNegatedOperators["reportModalityOperator"]
                                  }
                                  checked={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "isNotEqual"
                                  }
                                  onChange={(value) =>
                                    setSelectedNegatedOperators({
                                      ...selectedNegatedOperators,
                                      reportModalityOperator: {
                                        value: "isNotEqual",
                                        label: "is not equal to",
                                        category: "report_modality",
                                      },
                                    })
                                  }
                                  borderColor={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "isNotEqual"
                                      ? "#00AEEF"
                                      : "#D4D4D4"
                                  }
                                  backgroundColor={
                                    selectedNegatedOperators["reportModalityOperator"]
                                      .value === "isNotEqual"
                                      ? "#00AEEF"
                                      : "#FFF"
                                  }
                                />
                                exclude
                              </Label>
                            </RadioGroup>
                          </SelectContainer>
                        </div>
                      }
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={modalityReportOptions}
                        value={selectedValues["report_modality"]}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            report_modality: value,
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>
                    <SelectContainer>
                      <SelectDropdownTitle>Exam Code</SelectDropdownTitle>
                      <div>
                        <SelectContainer
                          style={{ marginBottom: "6px" }}
                          key={`item_1`}
                        >
                          <RadioGroup>
                            <Label>
                              <RadioBtn
                                type="radio"
                                value={selectedNegatedOperators["examCodeOperator"]}
                                checked={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "none"
                                }
                                onChange={(value) =>
                                  setSelectedNegatedOperators({
                                    ...selectedNegatedOperators,
                                    examCodeOperator: {
                                      value: "none",
                                      label: "none",
                                      category: "exam_code",
                                    },
                                  })
                                }
                                borderColor={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "none"
                                    ? "#00AEEF"
                                    : "#D4D4D4"
                                }
                                backgroundColor={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "none"
                                    ? "#00AEEF"
                                    : "#FFF"
                                }
                              />
                              include
                            </Label>
                            <Label>
                              <RadioBtn
                                type="radio"
                                value={selectedNegatedOperators["examCodeOperator"]}
                                checked={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "isNotEqual"
                                }
                                onChange={(value) =>
                                  setSelectedNegatedOperators({
                                    ...selectedNegatedOperators,
                                    examCodeOperator: {
                                      value: "isNotEqual",
                                      label: "is not equal to",
                                      category: "exam_code",
                                    },
                                  })
                                }
                                borderColor={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "isNotEqual"
                                    ? "#00AEEF"
                                    : "#D4D4D4"
                                }
                                backgroundColor={
                                  selectedNegatedOperators["examCodeOperator"]
                                    .value === "isNotEqual"
                                    ? "#00AEEF"
                                    : "#FFF"
                                }
                              />
                              exclude
                            </Label>
                          </RadioGroup>
                        </SelectContainer>
                      </div>
                      <input
                        style={{
                          resize: "none",
                          border: validationError
                            ? "1px solid #B40000"
                            : "1px solid #D2D3D7",
                          borderRadius: "4px",
                          padding: "10px",
                          width: "227px",
                          height: "43.5px",
                          fontSize: "14px",
                        }}
                        value={selectedValues["exam_code"]
                          ?.map((itm) => itm.value)
                          ?.join(",")}
                        onChange={handleInputChange}
                        placeholder="Enter 5 digit exam code"
                      />

                      {selectedValues["exam_code"]?.length > 0 &&
                        validationError && (
                          <div
                            style={{
                              color: "#B40000",
                              fontSize: "14px",
                              fontWeight: 400,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: validationError,
                            }}
                          ></div>
                        )}
                    </SelectContainer>
                  </Row>
                  <Row>
                    <DateFilterContainer>
                      <TimeframeContainer>
                        <Timeframe>Exam Date</Timeframe>

                        <DatePickerContainer>
                          <DatePicker
                            popperPlacement={"auto"}
                            selected={
                              selectedValues["exam_date"]?.[0]?.startDate
                            }
                            onChange={onChange}
                            startDate={
                              selectedValues["exam_date"]?.[0]?.startDate
                            }
                            endDate={selectedValues["exam_date"]?.[0]?.endDate}
                            customInput={
                              <DatePickerInput
                                startDate={
                                  selectedValues["exam_date"]?.[0]?.startDate
                                }
                                endDate={
                                  selectedValues["exam_date"]?.[0]?.endDate
                                }
                              />
                            }
                            shouldCloseOnSelect={false}
                            selectsRange
                            dateFormat={"dd/MM/yyyy"}
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "5px, 10px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                          />
                        </DatePickerContainer>
                      </TimeframeContainer>
                    </DateFilterContainer>
                    <SelectContainer>
                      <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                      <Select
                        components={{
                          IndicatorsContainer,
                          Option: RadioOption,
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={messageSentOptions}
                        value={selectedValues["message_sent"]}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            message_sent: [value],
                          })
                        }
                        styles={{
                          option: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                          }),
                          placeholder: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </SelectContainer>
                  </Row>
                  <Row>
                    <SelectContainer>
                      <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={radiologistOptions}
                        value={selectedValues["radiologist"]}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        allowSelectAll={true}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            radiologist: value,
                          })
                        }
                        styles={colourStyles}
                      />
                    </SelectContainer>
                    <SelectContainer>
                      <SelectDropdownTitle>
                        Referring Physician
                      </SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={referrerOptionsList}
                        value={selectedValues["referrers"]}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        allowSelectAll={true}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            referrers: value,
                          })
                        }
                        styles={colourStyles}
                        onInputChange={onInputChange}
                        placeholder={
                          loading ? "Searching" : "Search by lastname"
                        }
                        isLoading={loading}
                        onMenuScrollToBottom={loadMoreOptions}
                        onFocus={() => setReferrerList([])}
                      />
                    </SelectContainer>
                  </Row>
                  <Row>
                    <SelectContainer width="464px">
                      <SelectDropdownTitle>
                        Location of Practice
                      </SelectDropdownTitle>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={locationOptions}
                        value={selectedValues["location"]}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          IndicatorsContainer,
                        }}
                        allowSelectAll={true}
                        onChange={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            location: value,
                          })
                        }
                        styles={colourStyles}
                        onMenuScrollToBottom={loadMoreLocationOptions}
                        isLoading={loadingMoreLocations}
                      />
                    </SelectContainer>
                  </Row>
                  <Row
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: "20px",
                      gap: "6px",
                      flexWrap: "wrap",
                      maxWidth: "464px",
                    }}
                  >
                    {Object.values(selectedValues).map((item, index) => {
                      if (!item || !Array.isArray(item)) return null;
                      return item.map((i, idx) => (
                        <SelectedItemContainer key={index + idx}>
                          <SelectedItem>
                            {" "}
                            {i.category
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, function (char) {
                                return char.toUpperCase();
                              })}{" "}
                            :{" "}
                            {((i.category === "report_modality" &&
                              selectedNegatedOperators.reportModalityOperator.value ===
                                "isNotEqual") ||
                              (i.category === "exam_code" &&
                                selectedNegatedOperators.examCodeOperator.value ===
                                  "isNotEqual")) &&
                              "Is Not"}{" "}
                            {i.category === "referrers" ||
                            i.category === "radiologist"
                              ? i.label
                              : i.value.replace(/\b\w/g, function (char) {
                                  return char.toUpperCase();
                                })}
                          </SelectedItem>
                          <CloseSign
                            onClick={() => closeSelected(i.category, i)}
                          >
                            <Image src={close} alt={"close sign"} />
                          </CloseSign>
                        </SelectedItemContainer>
                      ));
                    })}
                  </Row>
                  <Row justifyContent="flex-end" gap="18px">
                    <Button
                      color="#000000"
                      border="2px solid #CFCFCF"
                      bg="#FFF"
                    >
                      Cancel
                    </Button>
                    <Button
                      bg="#00AEEF"
                      color="#FFFFFF"
                      isPrimary={true}
                      disabled={
                        selectedValues["exam_code"]?.length > 0 &&
                        validationError
                          ? true
                          : false
                      }
                    >
                      Apply Filters
                    </Button>
                  </Row>
                </div>
              </form>
            </FormContainer>
          </div>
        </Card>
      </ModalContentContainer>
    </Modal>
  );
};
