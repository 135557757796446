import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import {
  getCustomChartData,
  saveCustomChartData,
} from "../../../actions/radminCustomChartData";
import {
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Rectangle,
  Sector,
} from "recharts";
import moment from "moment";
import Filter from "../Messages/Filter";
import { useHistory } from "react-router";
import { Spinner } from "react-bootstrap";
import {
  getAnatomiesListAction,
  getLocations,
  getModalitiesListAction,
  getRadiologistForDropdown,
  getReportsModality,
} from "../../../actions/radminUploads";
import {
  DEFAULT_VALUES,
  ageOptions,
  dataSubsetEmailsOptions,
  dataSubsetPatientsOptions,
  dataSubsetRadiologistsOptions,
  dataSubsetRecommendationsOptions,
  dataSubsetReferrersOptions,
  dataSubsetReportsOptions,
  dataSubsetSmsOptions,
  dataTypeOptions,
  emailsStatusOptions,
  frequencyOptions,
  messageSentOptions,
  nonTimeSeriesChartTypeOptions,
  noteOptions,
  recommendationTimeframeStatusOptions,
  smsDirectionOptions,
  timeseriesChartTypeOptions,
  timeframeOptions,
  anatomyTypeOptions,
  reportModalityTypeOptions,
  modalityTypeOptions,
  recommendationStatusColors,
  smsColors,
  emailColors,
  SelectDropdownTitle,
  SelectContainer,
  COLORS,
  LegendListContainer,
  LegendList,
  LegendListItem,
  SidebarContainer,
  Input,
  TimeframeContainer,
  DateFilterContainer,
  legendWrapperStyle,
  recommendationsGroupOptions,
  dataSubsetNotesOptions,
  randomColor,
  Container,
  flowTypeOptions,
  dataTypeComparisonOptions,
  smsStatusOptions,
  recoStatusOptions,
  timeframeOptionsPrecedingDay,
  dataSubsetRecommendationsPieOptions,
} from "./Constants";
import * as Sentry from "@sentry/react";
import { capitalize } from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import Flex from "../../../components/_styles/Flex";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import styled from "styled-components";
import { createPortal } from "react-dom";

const CalendarContainer = ({ children }) => {
  return createPortal(children, document.body);
};

const MultiOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ margin: "0 5px", verticalAlign: "middle" }}
        />{" "}
        <label style={{ margin: "0" }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";

  return (
    <g>
      <text
        x={cx}
        y={cy - 30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 900 }}
      >
        {percentage}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={"#A6ACBE"}
        style={{ fontSize: "10px", lineHeight: "13px", fontWeight: 600 }}
      >
        {payload?.status || payload?.gender}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "none",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rectangle: {
    width: "80%",
    height: 200,
    backgroundColor: "blue",
    marginTop: 20,
  },
  accordionRoot: {
    boxShadow: "none", // Remove the box shadow
    border: "none", // Remove the border
    "&:before": {
      display: "none", // Hide the border before pseudo-element
    },
    "&$expanded": {
      margin: 0, // Optionally, remove margin when expanded
    },
  },
}));

const CustomChartsCreate = () => {
  const [selectedValues, setSelectedValues] = useState(DEFAULT_VALUES);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState();
  const [selectedDate, setSelectedDate] = useState("");

  const [startDateNlpRangeStart, setStartDateNlpRangeStart] = useState(null);
  const [endDateNlpRangeStart, setEndDateNlpRangeStart] = useState(null);
  const [filterValueNlpRangeStart, setFilterValueNlpRangeStart] = useState();
  const [selectedDateNlpRangeStart, setSelectedDateNlpRangeStart] =
    useState("");

  const [startDateNlpRangeEnd, setStartDateNlpRangeEnd] = useState(null);
  const [endDateNlpRangeEnd, setEndDateNlpRangeEnd] = useState(null);
  const [filterValueNlpRangeEnd, setFilterValueNlpRangeEnd] = useState();
  const [selectedDateNlpRangeEnd, setSelectedDateNlpRangeEnd] = useState("");

  const [startDateExamDate, setStartDateExamDate] = useState(null);
  const [endDateExamDate, setEndDateExamDate] = useState(null);
  const [filterValueExamDate, setFilterValueExamDate] = useState();
  const [selectedDateExamDate, setSelectedDateExamDate] = useState("");
  const handleResetDates = () => {
    setStartDateNlpRangeStart(null);
    setEndDateNlpRangeStart(null);
    setFilterValueNlpRangeStart(null);
    setSelectedDateNlpRangeStart(null);
    setStartDateNlpRangeEnd(null);
    setEndDateNlpRangeEnd(null);
    setFilterValueNlpRangeEnd(null);
    setSelectedDateNlpRangeEnd(null);
    setStartDateExamDate(null);
    setEndDateExamDate(null);
    setFilterValueExamDate(null);
    setSelectedDateExamDate(null);
  };

  const classes = useStyles();

  const radiologists = useSelector(
    (state) => state.radAdmin.followUpList?.dropdownRadiologists.data
  );
  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );

  const radiologistOptions = radiologists?.length
    ? radiologists
        .map((item) => ({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name}`,
          category: "radiologist",
        }))
        .filter((item) => item.value)
    : [];
  const modalityOptions = modalities?.length
    ? modalities
        .map((item) => ({ value: item, label: item, category: "modality" }))
        .filter((item) => item.value)
    : [];
  const anatomyOptions = anatomies?.length
    ? anatomies
        .map((item) => ({ value: item, label: item, category: "anatomy" }))
        .filter((item) => item.value)
    : [];

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const chartData = {
      title,
      filters: JSON.stringify({
        ...selectedValues,
        timeframe: filterValue,
        startDate,
        endDate,
        selectedDate,
      }),
    };

    dispatch(saveCustomChartData(chartData))
      .then((res) => {
        setIsLoading(false);
        window.analytics.track("Custom-Charts-Save-Click", { ...chartData });
        history.push(`/auth/radAdmin/custom/charts/view`);
      })
      .catch((err) => Sentry.captureException(err));
  };

  const Option = ({ type, ...props }) => {
    return (
      <>
        <components.Option {...props} >
          <div style={{display:'flex', gap:'5px', alignItems:'center'}}>
          <span>
            <input
              type={"radio"}
              checked={props.isSelected}
              onChange={() => null}
              style={{ margin: 0, padding:0 }}
            />{" "}
          </span>
          <label style={{ margin: 0 , padding:0 }}>{props.label}</label>
          </div>
        </components.Option>
      </>
    );
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: isLoading ? "#eeeeee" : "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValue.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValue.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDate(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDate(requiredDate);
      }
    }
  }, [filterValue]);

  React.useEffect(() => {
    if (filterValueNlpRangeStart) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueNlpRangeStart.value === "Custom date range") return;
      setStartDateNlpRangeStart("");
      setEndDateNlpRangeStart("");
      if (filterValueNlpRangeStart.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueNlpRangeStart.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueNlpRangeStart.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueNlpRangeStart.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueNlpRangeStart.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateNlpRangeStart(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateNlpRangeStart(requiredDate);
      }
    }else{
      setStartDateNlpRangeStart(null);
      setEndDateNlpRangeStart(null);
      setSelectedDateNlpRangeStart(null);

    }
  }, [filterValueNlpRangeStart]);

  useEffect(() => {
    let recKey =
    selectedValues["data_type"]?.value === "recommendations-pie"
      ? "recommendations-pie"
      : "recommendations";
    if (filterValueNlpRangeStart?.value) {
     
      if (filterValueNlpRangeStart.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_start_gte: startDateNlpRangeStart
                ? { value: startDateNlpRangeStart }
                : "",
              nlp_range_start_lte: endDateNlpRangeStart
                ? { value: endDateNlpRangeStart }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_start_gte: selectedDateNlpRangeStart
                ? { value: selectedDateNlpRangeStart }
                : "",
              nlp_range_start_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    }else{
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            nlp_range_start_gte: "",
            nlp_range_start_lte: ""
          },
        },
      });

    }
  }, [selectedDateNlpRangeStart, startDateNlpRangeStart, endDateNlpRangeStart]);

  React.useEffect(() => {
    if (filterValueNlpRangeEnd) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueNlpRangeEnd.value === "Custom date range") return;
      setStartDateNlpRangeEnd("");
      setEndDateNlpRangeEnd("");
      if (filterValueNlpRangeEnd.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueNlpRangeEnd.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueNlpRangeEnd.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueNlpRangeEnd.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueNlpRangeEnd.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateNlpRangeEnd(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateNlpRangeEnd(requiredDate);
      }
    }
    else{
      setStartDateNlpRangeEnd(null);
      setEndDateNlpRangeEnd(null);
      setSelectedDateNlpRangeEnd(null);
    }
  }, [filterValueNlpRangeEnd]);

  useEffect(() => {
    let recKey =
    selectedValues["data_type"]?.value === "recommendations-pie"
      ? "recommendations-pie"
      : "recommendations";
    if (filterValueNlpRangeEnd?.value) {
  
      if (filterValueNlpRangeEnd.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_end_gte: startDateNlpRangeEnd
                ? { value: startDateNlpRangeEnd }
                : "",
              nlp_range_end_lte: endDateNlpRangeEnd
                ? { value: endDateNlpRangeEnd }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],
              nlp_range_end_gte: selectedDateNlpRangeEnd
                ? { value: selectedDateNlpRangeEnd }
                : "",
              nlp_range_end_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    } else{
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            nlp_range_end_gte: "",
            nlp_range_end_lte: ""
          },
        },
      });

    }
  }, [selectedDateNlpRangeEnd, startDateNlpRangeEnd, endDateNlpRangeEnd]);

  React.useEffect(() => {
    if (filterValueExamDate) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValueExamDate.value === "Custom date range") return;
      setStartDateExamDate("");
      setEndDateExamDate("");
      if (filterValueExamDate.value === "Last 7 days") {
        lastMoment = new Date(moment().subtract(7, "days"));
      } else if (filterValueExamDate.value === "Last 30 days") {
        lastMoment = new Date(moment().subtract(30, "days"));
      } else if (filterValueExamDate.value === "Last 3 months") {
        lastMoment = new Date(moment().subtract(3, "months"));
      } else if (filterValueExamDate.value === "Last 6 months") {
        lastMoment = new Date(moment().subtract(6, "months"));
      }

      if (filterValueExamDate.value === "Last 12 months") {
        let dateObj = new Date(moment().subtract(1, "year"));
        requiredDate = moment(dateObj).format("YYYY-MM-DD");
        setSelectedDateExamDate(requiredDate);
        return;
      } else {
        requiredDate = moment(lastMoment).format("YYYY-MM-DD");
        setSelectedDateExamDate(requiredDate);
      }
    }else{
        setStartDateExamDate(null);
        setEndDateExamDate(null);
        setSelectedDateExamDate(null);

    }
  }, [filterValueExamDate]);

  useEffect(() => {
    let recKey =
    selectedValues["data_type"]?.value === "recommendations-pie"
      ? "recommendations-pie"
      : "recommendations";
    if (filterValueExamDate?.value) {
    

      if (filterValueExamDate.value === "Custom date range") {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],

              initial_exam_date_gte: startDateExamDate
                ? { value: startDateExamDate }
                : "",
              initial_exam_date_lte: endDateExamDate
                ? { value: endDateExamDate }
                : "",
            },
          },
        });
      } else {
        setSelectedValues({
          ...selectedValues,
          additional: {
            ...selectedValues.additional,
            [recKey]: {
              ...selectedValues.additional[recKey],

              initial_exam_date_gte: selectedDateExamDate
                ? { value: selectedDateExamDate }
                : "",
              initial_exam_date_lte: {
                value: moment(new Date()).format("YYYY-MM-DD"),
              },
            },
          },
        });
      }
    }else{
      setSelectedValues({
        ...selectedValues,
        additional: {
          ...selectedValues.additional,
          [recKey]: {
            ...selectedValues.additional[recKey],
            initial_exam_date_gte: "",
            initial_exam_date_lte: ""
          },
        },
      });

    }
  }, [selectedDateExamDate, startDateExamDate, endDateExamDate]);

  useEffect(() => {
    setData([]);
    if (
      selectedValues["chart_type"]?.value === "line" ||
      selectedValues["chart_type"]?.value === "bar"
        ? selectedValues["data_type"] &&
          selectedValues["data_subset"] &&
          selectedValues["chart_type"] &&
          selectedValues["frequency"] &&
          selectedValues["group_by"] &&
          filterValue
        : selectedValues["data_type"] &&
          selectedValues["data_subset"] &&
          selectedValues["chart_type"]
    ) {
      if (filterValue?.value === "Custom date range" && startDate && endDate) {
        setLoader(true);
        
        dispatch(
          getCustomChartData(selectedValues, startDate, endDate, filterValue)
        )

        dispatch(
          getCustomChartData(selectedValues, startDate, endDate, filterValue)
        )

          .then((res) => {
            setData(res);
          })
          .catch((err) => Sentry.captureException(err))
          .finally(() => setLoader(false));
      } else if (filterValue?.value !== "Custom date range") {
        setLoader(true);

        dispatch(
          getCustomChartData(
            selectedValues,
            selectedDate,
            moment(new Date()).format("YYYY-MM-DD"),
            filterValue
          )
        )
          .then((res) => {
            setData(res);
          })
          .catch((err) => Sentry.captureException(err))
          .finally(() => setLoader(false));
      }
    }
  }, [selectedValues, selectedDate, startDate, endDate]);

  useEffect(() => {
    dispatch(getRadiologistForDropdown());
    dispatch(getModalitiesListAction());
    dispatch(getAnatomiesListAction());
  }, [dispatch]);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <LegendListContainer>
        <LegendList>
          {payload
            .sort((a, b) => b.payload.id__count - a.payload.id__count)
            .map((entry, index) => (
              <LegendListItem
                key={index}
                color={COLORS[index % COLORS.length]}
              >{`${
                selectedValues["data_type"]?.value === "radiologists" ||
                selectedValues["data_type"]?.value === "referrers"
                  ? entry.payload["status"].replaceAll("_", " ")
                  : entry.payload[selectedValues["data_subset"].value]
              } - ${entry.payload.id__count}`}</LegendListItem>
            ))}
        </LegendList>
      </LegendListContainer>
    );
  };
  let flattenData = [];
  let uniqueStatuses = [];

  if (
    (selectedValues["data_type"]?.value === "recommendations" ||
      selectedValues["data_type"]?.value === "sms" ||
      selectedValues["data_type"]?.value === "emails") &&
    selectedValues["flow_type"]?.value === "individual" &&
    data?.length > 0
  ) {
    flattenData = data?.map((item) => {
      const obj = {};
      if (item?.data?.length > 0) {
        item.data.forEach((d) => {
          if (d.status && d.modality && d.anatomy) {
            obj[d.status + " " + d.modality + " " + d.anatomy] = d["id__count"];
          } else if (d.modality && d.anatomy) {
            obj[d.modality + " " + d.anatomy] = d["id__count"];
          } else if (d.status && d.modality) {
            obj[d.status + " " + d.modality] = d["id__count"];
          } else if (d.status && d.anatomy) {
            obj[d.status + " " + d.anatomy] = d["id__count"];
          } else {
            obj[d.status || d.modality || d.anatomy] = d["id__count"];
          }
        });
      }
      return { date: item.date, ...obj };
    });

    uniqueStatuses = [
      ...new Set(
        data?.flatMap((item) =>
          item.data?.map((d) =>
            d.status && d.modality && d.anatomy
              ? d.status + " " + d.modality + " " + d.anatomy
              : d.status && d.modality
              ? d.status + " " + d.modality
              : d.modality && d.anatomy
              ? d.modality + " " + d.anatomy
              : d.anatomy && d.status
              ? d.status + " " + d.anatomy
              : d.status || d.modality || d.anatomy
          )
        )
      ),
    ];
  }

  const [loadingMoreLocations, setLoadingMoreLocations] = useState(false);
  const [locationOptions, setLocationOptions] = useState();
  const [nextLocationPage, setNextLocationPage] = useState();

  useEffect(() => {
    dispatch(getLocations()).then((res) => {
      if(res){
        if (res.next) {
          setNextLocationPage(2);
        }
        setLocationOptions(
          res.results.map((itm) => ({
            category: "location",
            label: itm.location_address.replace(/\^/g, ","),
            value: itm.location_address,
          }))
        );
      }
    });
  }, []);

  const loadMoreLocationOptions = async () => {
    if (nextLocationPage) {
      setLoadingMoreLocations(true);
      dispatch(getLocations(nextLocationPage)).then((res) => {
        setLoadingMoreLocations(false);
        if(res){
          setLocationOptions((prev) => [
            ...prev,
            ...res.results.map((itm) => ({
              category: "location",
              label: itm.location_address.replace(/\^/g, ","),
              value: itm.location_address,
            })),
          ]);
          if (res.next) {
            setNextLocationPage((prev) => prev + 1);
          } else {
            setNextLocationPage();
          }
        }
      });
    }
  };

  const [modalityReportOptions, setModalityReportOptions] = useState([]);

  useEffect(() => {
    dispatch(getReportsModality())
      .then((res) =>
        setModalityReportOptions(
          res.map((itm) => ({
            label: itm.report__modality,
            value: itm.report__modality,
            category: "report_modality",
          }))
        )
      )
      .catch((err) => Sentry.captureException(err));
  }, []);

  const styles = {
    option: (styles) => ({
      ...styles,
      fontSize: "14px",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: isLoading ? "#eeeeee" : "white",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isLoading ? "#eeeeee" : "white",
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      borderRadius: "25px",
      backgroundColor: "#EEEEEE",
      color: "#A1A1A1",
      padding: "4px 10px",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#A1A1A1",
      fontSize: "10px",
    }),
  };

  const DatePickerInput = forwardRef(
    ({ onClick, startDate, endDate, startKey, endKey }, ref) => {
      const formatDate = (date) => {
        return date ? moment(date).format("YYYY-MM-DD") : "No Date";
      };

      return (
        <DatePickerButton onClick={onClick} ref={ref}>
          <DateContainer>
            {!startDate && !endDate
              ? "Select..."
              : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </DateContainer>
          {!startDate && !endDate && <CalendarIcon />}
          {(startDate || endDate) && (
            <DateClearButton onClick={() => resetDateFilter(startKey, endKey)}>
              <RemoveRecoIcon />
            </DateClearButton>
          )}
        </DatePickerButton>
      );
    }
  );

  const resetDateFilter = (startKey, endKey) => {
    setSelectedValues({
      ...selectedValues,
      additional: {
        ...selectedValues.additional,
        recommendations: {
          ...selectedValues.additional.recommendations,
          [startKey]: "",
          [endKey]: "",
        },
      },
    });
  };

  const DatePickerButton = styled(Flex)`
    padding: 10px 15px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d2d3d7;
    border-radius: 4px;
    font-size: 18px;
    width: 227px;
    display: flex;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
  `;
  const DateContainer = styled(Flex)`
    margin-right: 15px;
    font-size: 14px;
    color: #828282;
    font-family: Open Sans;
  `;

  const DateClearButton = styled.div`
    cursor: pointer;
    display: flex;
  `;

  return (
    <Container
      isTimeGraph={
        selectedValues["chart_type"]?.value === "bar" ||
        selectedValues["chart_type"]?.value === "line"
      }
      style={{ display: "flex", width: "100%", height: "100%" }}
    >
      <SidebarContainer>
        <h1
          style={{
            fontWeight: 700,
            fontSize: "36px",
            marginBottom: "24px",
            color: "#0C2945",
            fontFamily: "Roboto Condensed",
          }}
        >
          New Chart
        </h1>

        <SelectContainer>
          <SelectDropdownTitle>Flow Type</SelectDropdownTitle>
          <Select
            components={{ IndicatorsContainer, Option }}
            className="basic-multi-select"
            isDisabled={isLoading}
            classNamePrefix="select"
            options={flowTypeOptions}
            value={selectedValues["flow_type"]}
            onChange={(value) => {
              setSelectedValues({
                ...selectedValues,
                flow_type: value,
                data_type: "",
                data_subset: "",
                chart_type: "",
                frequency: "",
                group_by: [
                  { value: "status", label: "Status", category: "status" },
                ],
                compare_preceeding: false,
                additional: {
                  recommendations: {
                    status: "",
                    timeframe_status_extracted: "",
                    modality: "",
                    anatomy: "",
                    age: "",
                    message_sent: "",
                    has_note: "",
                    location_address: "",
                    report__radiologist__id: "",
                    report__modality: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                    anatomy_type: {
                      value: true,
                      label: "Include",
                      category: "anatomy_type",
                    },
                    report__modality_type: {
                      value: true,
                      label: "Include",
                      category: "report__modality_type",
                    },
                    initial_exam_date_gte: "",
                    initial_exam_date_lte: "",
                    nlp_range_start_gte: "",
                    nlp_range_start_lte: "",
                    nlp_range_end_gte: "",
                    nlp_range_end_lte: "",
                  },
                  reports: {
                    modality: "",
                    radiologist: "",
                    message_sent: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                  },
                  patients: {},
                  referrers: {},
                  radiologists: {},
                  emails: {
                    status: "",
                  },
                  sms: {
                    direction: "",
                    status: "",
                  },
                  notes: {},
                  "recommendations-pie": {
                    status: "",
                    timeframe_status_extracted: "",
                    modality: "",
                    anatomy: "",
                    age: "",
                    message_sent: "",
                    has_note: "",
                    location_address: "",
                    report__radiologist__id: "",
                    report__modality: "",
                    modality_type: {
                      value: true,
                      label: "Include",
                      category: "modality_type",
                    },
                    anatomy_type: {
                      value: true,
                      label: "Include",
                      category: "anatomy_type",
                    },
                    report__modality_type: {
                      value: true,
                      label: "Include",
                      category: "report__modality_type",
                    },
                    initial_exam_date_gte: "",
                    initial_exam_date_lte: "",
                    nlp_range_start_gte: "",
                    nlp_range_start_lte: "",
                    nlp_range_end_gte: "",
                    nlp_range_end_lte: "",
                  },

                },
              });
              setFilterValue();
              setSelectedDate();
              setStartDate();
              setEndDate();
              handleResetDates();
            }}
            styles={styles}
          />
        </SelectContainer>
        {selectedValues["flow_type"]?.value === "individual" ? (
          <form onSubmit={handleFormSubmit}>
            <SelectContainer>
              <SelectDropdownTitle>Data Type *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                isDisabled={isLoading}
                classNamePrefix="select"
                options={dataTypeOptions}
                value={selectedValues["data_type"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_type: value,
                    data_subset: "",
                    chart_type: "",
                    frequency: "",
                    group_by: [
                      { value: "status", label: "Status", category: "status" },
                    ],
                    additional: {
                      recommendations: {
                        status: "",
                        timeframe_status_extracted: "",
                        modality: "",
                        anatomy: "",
                        age: "",
                        message_sent: "",
                        has_note: "",
                        location_address: "",
                        report__radiologist__id: "",
                        report__modality: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                        anatomy_type: {
                          value: true,
                          label: "Include",
                          category: "anatomy_type",
                        },
                        report__modality_type: {
                          value: true,
                          label: "Include",
                          category: "report__modality_type",
                        },
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                      reports: {
                        modality: "",
                        radiologist: "",
                        message_sent: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                      },
                      patients: {},
                      referrers: {},
                      radiologists: {},
                      emails: {
                        status: "",
                      },
                      sms: {
                        direction: "",
                        status: "",
                      },
                      notes: {},
                      "recommendations-pie": {
                        status: "",
                        timeframe_status_extracted: "",
                        modality: "",
                        anatomy: "",
                        age: "",
                        message_sent: "",
                        has_note: "",
                        location_address: "",
                        report__radiologist__id: "",
                        report__modality: "",
                        modality_type: {
                          value: true,
                          label: "Include",
                          category: "modality_type",
                        },
                        anatomy_type: {
                          value: true,
                          label: "Include",
                          category: "anatomy_type",
                        },
                        report__modality_type: {
                          value: true,
                          label: "Include",
                          category: "report__modality_type",
                        },
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                    },
                  });
                  setFilterValue();
                  setSelectedDate();
                  setStartDate();
                  setEndDate();
                  handleResetDates();
                }}
                styles={styles}
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Data Subset *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                isDisabled={isLoading || !selectedValues["data_type"]}
                classNamePrefix="select"
                options={
                  selectedValues["data_type"]?.value === "recommendations"
                    ? dataSubsetRecommendationsOptions
                    : selectedValues["data_type"]?.value === "reports"
                    ? dataSubsetReportsOptions
                    : selectedValues["data_type"]?.value === "emails"
                    ? dataSubsetEmailsOptions
                    : selectedValues["data_type"]?.value === "sms"
                    ? dataSubsetSmsOptions
                    : selectedValues["data_type"]?.value === "radiologists"
                    ? dataSubsetRadiologistsOptions
                    : selectedValues["data_type"]?.value ===
                      "recommendations-pie"
                    ? dataSubsetRecommendationsPieOptions
                    : selectedValues["data_type"]?.value === "patients"
                    ? dataSubsetPatientsOptions
                    : selectedValues["data_type"]?.value === "notes"
                    ? dataSubsetNotesOptions
                    : dataSubsetReferrersOptions
                }
                value={selectedValues["data_subset"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_subset: value,
                    additional: {
                      ...selectedValues.additional,
                      recommendations: {
                        ...selectedValues.additional.recommendations,
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },
                      "recommendations-pie": {
                        ...selectedValues.additional["recommendations-pie"],
                        initial_exam_date_gte: "",
                        initial_exam_date_lte: "",
                        nlp_range_start_gte: "",
                        nlp_range_start_lte: "",
                        nlp_range_end_gte: "",
                        nlp_range_end_lte: "",
                      },

                    },
                  });
                  handleResetDates();
                }}
                styles={styles}
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Chart Type *</SelectDropdownTitle>
              <Select
                isDisabled={isLoading || !selectedValues["data_type"]}
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={
                  selectedValues["data_type"].value === "recommendations" ||
                  selectedValues["data_type"].value === "reports" ||
                  selectedValues["data_type"].value === "emails" ||
                  selectedValues["data_type"].value === "sms" ||
                  selectedValues["data_type"].value === "notes"
                    ? timeseriesChartTypeOptions
                    : nonTimeSeriesChartTypeOptions
                }
                value={selectedValues["chart_type"]}
                onChange={(value) =>
                  setSelectedValues({
                    ...selectedValues,
                    chart_type: value,
                  })
                }
                styles={styles}
              />
            </SelectContainer>
            {(selectedValues["chart_type"]?.value === "line" ||
              selectedValues["chart_type"]?.value === "bar") && (
              <SelectContainer>
                <SelectDropdownTitle>Frequency *</SelectDropdownTitle>
                <Select
                  isDisabled={isLoading}
                  components={{ IndicatorsContainer, Option }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={frequencyOptions}
                  value={selectedValues["frequency"]}
                  onChange={(value) => {
                    setSelectedValues({
                      ...selectedValues,
                      frequency: "",
                    });
                    setFilterValue();
                    setSelectedDate();
                    setStartDate();
                    setEndDate();
                    setTimeout(() => {
                      setSelectedValues({
                        ...selectedValues,
                        frequency: value,
                      });
                    }, 0);
                  }}
                  styles={styles}
                />
              </SelectContainer>
            )}
            {(selectedValues["chart_type"]?.value === "line" ||
              selectedValues["chart_type"]?.value === "bar") &&
              selectedValues["frequency"] && (
                <SelectContainer>
                  <SelectDropdownTitle>Timeframe *</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setSelectedDate={setSelectedDate}
                        type="radio"
                        isDisabled={isLoading || !selectedValues["frequency"]}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>
              )}
            {selectedValues["data_type"]?.value === "reports" && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={selectedValues["additional"].reports?.["message_sent"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option:MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    value={selectedValues["additional"].reports?.["modality"]}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            modality: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                    isMulti
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].reports?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    value={
                      selectedValues["additional"].reports?.["radiologist"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            radiologist: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}
            {selectedValues["data_type"]?.value === "recommendations" && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Group By *</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !== "range_start" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Nlp Range Start</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueNlpRangeStart}
                            setFilterValue={setFilterValueNlpRangeStart}
                            startDate={startDateNlpRangeStart}
                            setStartDate={setStartDateNlpRangeStart}
                            endDate={endDateNlpRangeStart}
                            setEndDate={setEndDateNlpRangeStart}
                            setSelectedDate={setSelectedDateNlpRangeStart}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}

                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !== "range_end" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Nlp Range End</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueNlpRangeEnd}
                            setFilterValue={setFilterValueNlpRangeEnd}
                            startDate={startDateNlpRangeEnd}
                            setStartDate={setStartDateNlpRangeEnd}
                            endDate={endDateNlpRangeEnd}
                            setEndDate={setEndDateNlpRangeEnd}
                            setSelectedDate={setSelectedDateNlpRangeEnd}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}
                {selectedValues["data_subset"]?.value &&
                  selectedValues["data_subset"]?.value !==
                    "initial_exam_date" && (
                    <SelectContainer>
                      <SelectDropdownTitle>Exam Range</SelectDropdownTitle>
                      <DateFilterContainer>
                        <TimeframeContainer>
                          <Filter
                            selectContainerStyles={{ width: "227px" }}
                            options={timeframeOptions}
                            filterValue={filterValueExamDate}
                            setFilterValue={setFilterValueExamDate}
                            startDate={startDateExamDate}
                            setStartDate={setStartDateExamDate}
                            endDate={endDateExamDate}
                            setEndDate={setEndDateExamDate}
                            setSelectedDate={setSelectedDateExamDate}
                            type="radio"
                            isDisabled={isLoading}
                            hasPortal={true}
                            displayFormat="YYYY-MM-DD"
                            isClearable={true}
                          />
                        </TimeframeContainer>
                      </DateFilterContainer>
                    </SelectContainer>
                  )}

                <SelectContainer>
                  <SelectDropdownTitle>Timeframe Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationTimeframeStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "timeframe_status_extracted"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            timeframe_status_extracted: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.["modality"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.["anatomy"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            anatomy: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Anatomy Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "anatomy_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            anatomy_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>
                    Report Modality Type
                  </SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={reportModalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "report__radiologist__id"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            report__radiologist__id: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Location</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={locationOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onMenuScrollToBottom={loadMoreLocationOptions}
                    isLoading={loadingMoreLocations}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "location_address"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            location_address: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Age</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={ageOptions}
                    value={
                      selectedValues["additional"].recommendations?.["age"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            age: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Contains Note</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={noteOptions}
                    value={
                      selectedValues["additional"].recommendations?.["has_note"]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            has_note: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={
                      selectedValues["additional"].recommendations?.[
                        "message_sent"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          recommendations: {
                            ...selectedValues.additional.recommendations,
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedValues["data_type"]?.value === "emails" && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Email Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={emailsStatusOptions}
                    value={selectedValues["additional"].emails?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          emails: {
                            ...selectedValues.additional.emails,
                            status: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedValues["data_type"]?.value === "sms" && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Sms Direction</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsDirectionOptions}
                    value={selectedValues["additional"].sms?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          sms: {
                            ...selectedValues.additional.sms,
                            direction: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            {selectedValues["data_type"]?.value === "recommendations-pie" && (
              <>
                <SelectContainer>
                  <SelectDropdownTitle>Nlp Range Start</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueNlpRangeStart}
                        setFilterValue={setFilterValueNlpRangeStart}
                        startDate={startDateNlpRangeStart}
                        setStartDate={setStartDateNlpRangeStart}
                        endDate={endDateNlpRangeStart}
                        setEndDate={setEndDateNlpRangeStart}
                        setSelectedDate={setSelectedDateNlpRangeStart}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Nlp Range End</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueNlpRangeEnd}
                        setFilterValue={setFilterValueNlpRangeEnd}
                        startDate={startDateNlpRangeEnd}
                        setStartDate={setStartDateNlpRangeEnd}
                        endDate={endDateNlpRangeEnd}
                        setEndDate={setEndDateNlpRangeEnd}
                        setSelectedDate={setSelectedDateNlpRangeEnd}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Exam Range</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={timeframeOptions}
                        filterValue={filterValueExamDate}
                        setFilterValue={setFilterValueExamDate}
                        startDate={startDateExamDate}
                        setStartDate={setStartDateExamDate}
                        endDate={endDateExamDate}
                        setEndDate={setEndDateExamDate}
                        setSelectedDate={setSelectedDateExamDate}
                        type="radio"
                        isDisabled={isLoading}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                        isClearable={true}
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Timeframe Status</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationTimeframeStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]?.["recommendations-pie"]?.[
                        "timeframe_status_extracted"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          "recommendations-pie": {
                            ...selectedValues.additional["recommendations-pie"],
                            timeframe_status_extracted: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          "recommendations-pie": {
                            ...selectedValues.additional["recommendations-pie"],
                            modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "anatomy"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            anatomy: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Anatomy Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={anatomyTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "anatomy_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            anatomy_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Report Modality</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityReportOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__modality"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__modality: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>
                    Report Modality Type
                  </SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={reportModalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SelectDropdownTitle>Radiologist</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={radiologistOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "report__radiologist__id"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            report__radiologist__id: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Location</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={locationOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onMenuScrollToBottom={loadMoreLocationOptions}
                    isLoading={loadingMoreLocations}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "location_address"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            location_address: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Age</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={ageOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "age"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            age: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Contains Note</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={noteOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "has_note"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            has_note: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={
                      selectedValues["additional"]["recommendations-pie"]?.[
                        "message_sent"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          ["recommendations-pie"]: {
                            ...selectedValues.additional["recommendations-pie"],
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
              </>
            )}

            <div>
              <SelectDropdownTitle>Chart Title *</SelectDropdownTitle>
              <Input
                disabled={isLoading}
                type="text"
                value={title}
                minLength={5}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  border: "1px solid #C2CCD9",
                  width: "227px",
                  height: "39px",
                  borderRadius: "4px",
                  background: isLoading ? "#eeeeee" : "#ffffff",
                }}
              />
            </div>
          </form>
        ) : selectedValues["flow_type"]?.value === "compare" ? (
          <form onSubmit={handleFormSubmit}>
            <SelectContainer>
              <SelectDropdownTitle>Data Type *</SelectDropdownTitle>
              <Select
                components={{ IndicatorsContainer, Option: MultiOption }}
                className="basic-multi-select"
                isDisabled={isLoading}
                classNamePrefix="select"
                options={dataTypeComparisonOptions}
                value={selectedValues["data_type"]}
                onChange={(value) => {
                  setSelectedValues({
                    ...selectedValues,
                    data_type: value,
                    group_by: [
                      { value: "status", label: "Status", category: "status" },
                    ],
                  });
                }}
                styles={styles}
                isMulti
              />
            </SelectContainer>

            <SelectContainer>
              <SelectDropdownTitle>Chart Type *</SelectDropdownTitle>
              <Select
                isDisabled={isLoading || !selectedValues["data_type"]}
                components={{ IndicatorsContainer, Option }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={timeseriesChartTypeOptions}
                value={selectedValues["chart_type"]}
                onChange={(value) =>
                  setSelectedValues({
                    ...selectedValues,
                    chart_type: value,
                  })
                }
                styles={styles}
              />
            </SelectContainer>
            {(selectedValues["chart_type"]?.value === "line" ||
              selectedValues["chart_type"]?.value === "bar") && (
              <SelectContainer>
                <SelectDropdownTitle>Frequency *</SelectDropdownTitle>
                <Select
                  isDisabled={isLoading}
                  components={{ IndicatorsContainer, Option }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={frequencyOptions}
                  value={selectedValues["frequency"]}
                  onChange={(value) => {
                    setSelectedValues({
                      ...selectedValues,
                      frequency: "",
                    });
                    setFilterValue();
                    setSelectedDate();
                    setStartDate();
                    setEndDate();
                    setTimeout(() => {
                      setSelectedValues({
                        ...selectedValues,
                        frequency: value,
                      });
                    }, 0);
                  }}
                  styles={styles}
                />
              </SelectContainer>
            )}
            {(selectedValues["chart_type"]?.value === "line" ||
              selectedValues["chart_type"]?.value === "bar") &&
              selectedValues["frequency"] && (
                <SelectContainer>
                  <SelectDropdownTitle>Timeframe *</SelectDropdownTitle>
                  <DateFilterContainer>
                    <TimeframeContainer>
                      <Filter
                        selectContainerStyles={{ width: "227px" }}
                        options={
                          selectedValues["frequency"]?.value === "days"
                            ? timeframeOptionsPrecedingDay
                            : timeframeOptions
                        }
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setSelectedDate={setSelectedDate}
                        type="radio"
                        isDisabled={isLoading || !selectedValues["frequency"]}
                        hasPortal={true}
                        displayFormat="YYYY-MM-DD"
                      />
                    </TimeframeContainer>
                  </DateFilterContainer>
                </SelectContainer>
              )}

            <div>
              <SelectDropdownTitle>Chart Title *</SelectDropdownTitle>
              <Input
                disabled={isLoading}
                type="text"
                value={title}
                minLength={5}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  border: "1px solid #C2CCD9",
                  width: "227px",
                  height: "39px",
                  borderRadius: "4px",
                  background: isLoading ? "#eeeeee" : "#ffffff",
                }}
              />
            </div>

            <label
              style={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#6D6E6E",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                checked={selectedValues["compare_preceeding"]}
                onChange={() => {
                  setSelectedValues({
                    ...selectedValues,
                    compare_preceeding: !selectedValues["compare_preceeding"],
                  });
                }}
              />
              Compare to preceding period
            </label>

            <div
              style={{ border: "1px solid #D2D3D7", marginBottom: "30px" }}
            />
            {Array.isArray(selectedValues["data_type"]) &&
              selectedValues["data_type"].map((itm, index) => (
                <div key={index} className={classes.root}>
                  <Accordion classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <p
                        style={{
                          color: "#0C2945",
                          fontFamily: "Roboto Condensed",
                          fontSize: "20px",
                          fontWeight: 500,
                          marginBottom: 0,
                        }}
                      >
                        {capitalize(itm.value)}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <SelectContainer>
                        <SelectDropdownTitle>Data Subset *</SelectDropdownTitle>
                        <Select
                          components={{ IndicatorsContainer, Option }}
                          className="basic-multi-select"
                          isDisabled={isLoading || !selectedValues["data_type"]}
                          classNamePrefix="select"
                          options={
                            itm.value === "recommendations"
                              ? dataSubsetRecommendationsOptions
                              : itm.value === "reports"
                              ? dataSubsetReportsOptions
                              : itm.value === "emails"
                              ? dataSubsetEmailsOptions
                              : itm.value === "sms"
                              ? dataSubsetSmsOptions
                              : itm.value === "radiologists"
                              ? dataSubsetRadiologistsOptions
                              : itm.value === "recommendations-pie"
                              ? dataSubsetRecommendationsPieOptions
                              : itm.value === "patients"
                              ? dataSubsetPatientsOptions
                              : itm.value === "notes"
                              ? dataSubsetNotesOptions
                              : dataSubsetReferrersOptions
                          }
                          value={selectedValues["data_subset"]?.[itm.value]}
                          onChange={(value) => {
                            setSelectedValues({
                              ...selectedValues,
                              data_subset: {
                                ...selectedValues.data_subset,
                                [itm.value]: value,
                              },
                              additional: {
                                ...selectedValues.additional,
                                recommendations: {
                                  ...selectedValues.additional.recommendations,
                                  initial_exam_date_gte: "",
                                  initial_exam_date_lte: "",
                                  nlp_range_start_gte: "",
                                  nlp_range_start_lte: "",
                                  nlp_range_end_gte: "",
                                  nlp_range_end_lte: "",
                                },
                              },
                            });
                            handleResetDates();
                          }}
                          styles={styles}
                        />
                      </SelectContainer>

                      {itm?.value === "reports" && (
                        <>
                            <SelectContainer>
                  <SelectDropdownTitle>Message Sent</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={messageSentOptions}
                    value={selectedValues["additional"].reports?.["message_sent"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            message_sent: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option:MultiOption }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityReportOptions}
                              value={
                                selectedValues["additional"].reports?.[
                                  "modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isMulti
                            />
                          </SelectContainer>
                          <SelectContainer>
                  <SelectDropdownTitle>Modality Type</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={modalityTypeOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={
                      selectedValues["additional"].reports?.[
                        "modality_type"
                      ]
                    }
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          reports: {
                            ...selectedValues.additional.reports,
                            modality_type: value,
                          },
                        },
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Radiologist
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={radiologistOptions}
                              value={
                                selectedValues["additional"].reports?.[
                                  "radiologist"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    reports: {
                                      ...selectedValues.additional.reports,
                                      radiologist: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "recommendations" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Recommendation Status *
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recoStatusOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "status"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          {/* <SelectContainer>
                  <SelectDropdownTitle>Group By *</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option: MultiOption }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={recommendationsGroupOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={selectedValues?.["group_by"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        group_by: value,
                      });
                    }}
                    styles={styles}
                  />
                </SelectContainer> */}
                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "range_start" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Nlp Range Start
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueNlpRangeStart}
                                    setFilterValue={setFilterValueNlpRangeStart}
                                    startDate={startDateNlpRangeStart}
                                    setStartDate={setStartDateNlpRangeStart}
                                    endDate={endDateNlpRangeStart}
                                    setEndDate={setEndDateNlpRangeStart}
                                    setSelectedDate={
                                      setSelectedDateNlpRangeStart
                                    }
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}

                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}

                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "range_end" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Nlp Range End
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueNlpRangeEnd}
                                    setFilterValue={setFilterValueNlpRangeEnd}
                                    startDate={startDateNlpRangeEnd}
                                    setStartDate={setStartDateNlpRangeEnd}
                                    endDate={endDateNlpRangeEnd}
                                    setEndDate={setEndDateNlpRangeEnd}
                                    setSelectedDate={setSelectedDateNlpRangeEnd}
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}

                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}
                          {selectedValues["data_subset"]?.[itm.value]?.value &&
                            selectedValues["data_subset"]?.[itm.value]
                              ?.value !== "initial_exam_date" && (
                              <SelectContainer>
                                <SelectDropdownTitle>
                                  Exam Range
                                </SelectDropdownTitle>
                                <TimeframeContainer>
                                  <Filter
                                    selectContainerStyles={{ width: "227px" }}
                                    options={timeframeOptions}
                                    filterValue={filterValueExamDate}
                                    setFilterValue={setFilterValueExamDate}
                                    startDate={startDateExamDate}
                                    setStartDate={setStartDateExamDate}
                                    endDate={endDateExamDate}
                                    setEndDate={setEndDateExamDate}
                                    setSelectedDate={setSelectedDateExamDate}
                                    type="radio"
                                    isDisabled={isLoading}
                                    hasPortal={true}
                                    displayFormat="YYYY-MM-DD"
                                    isClearable={true}

                                  />
                                </TimeframeContainer>
                              </SelectContainer>
                            )}

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Timeframe Status
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={recommendationTimeframeStatusOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "timeframe_status_extracted"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      timeframe_status_extracted: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Modality</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      modality: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Modality Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "modality_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      modality_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>Anatomy</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={anatomyOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "anatomy"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      anatomy: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Anatomy Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={anatomyTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "anatomy_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      anatomy_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Report Modality
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={modalityReportOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__modality"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__modality: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Report Modality Type
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: Option,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={reportModalityTypeOptions}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__modality_type"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__modality_type: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>

                          <SelectContainer>
                            <SelectDropdownTitle>
                              Radiologist
                            </SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={radiologistOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "report__radiologist__id"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      report__radiologist__id: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Location</SelectDropdownTitle>
                            <Select
                              components={{
                                IndicatorsContainer,
                                Option: MultiOption,
                              }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={locationOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onMenuScrollToBottom={loadMoreLocationOptions}
                              isLoading={loadingMoreLocations}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "location_address"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      location_address: value,
                                    },
                                  },
                                });
                              }}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>Age</SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={ageOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "age"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      age: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Contains Note
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={noteOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "has_note"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      has_note: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Message Sent
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={messageSentOptions}
                              value={
                                selectedValues["additional"].recommendations?.[
                                  "message_sent"
                                ]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    recommendations: {
                                      ...selectedValues.additional
                                        .recommendations,
                                      message_sent: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "emails" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Email Status *
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={emailsStatusOptions}
                              value={
                                selectedValues["additional"].emails?.["status"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    emails: {
                                      ...selectedValues.additional.emails,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                        </>
                      )}

                      {itm?.value === "sms" && (
                        <>
                          <SelectContainer>
                            <SelectDropdownTitle>
                              Sms Status *
                            </SelectDropdownTitle>
                            <Select
                              components={{ IndicatorsContainer, Option }}
                              className="basic-multi-select"
                              isDisabled={isLoading}
                              classNamePrefix="select"
                              options={smsStatusOptions}
                              value={
                                selectedValues["additional"].sms?.["status"]
                              }
                              onChange={(value) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  additional: {
                                    ...selectedValues.additional,
                                    sms: {
                                      ...selectedValues.additional.sms,
                                      status: value,
                                    },
                                  },
                                });
                              }}
                              isClearable={true}
                              styles={styles}
                            />
                          </SelectContainer>
                          {/* <SelectContainer>
                  <SelectDropdownTitle>Sms Direction</SelectDropdownTitle>
                  <Select
                    components={{ IndicatorsContainer, Option }}
                    className="basic-multi-select"
                    isDisabled={isLoading}
                    classNamePrefix="select"
                    options={smsDirectionOptions}
                    value={selectedValues["additional"].sms?.["status"]}
                    onChange={(value) => {
                      setSelectedValues({
                        ...selectedValues,
                        additional: {
                          ...selectedValues.additional,
                          sms: {
                            ...selectedValues.additional.sms,
                            direction: value,
                          },
                        },
                      });
                    }}
                    isClearable={true}
                    styles={styles}
                  />
                </SelectContainer> */}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </form>
        ) : null}
      </SidebarContainer>
      <div style={{ width: "90%", flex: 1, padding: "30px" }}>
        <div
          style={{
            height: "511px",
            border: "1px solid #DFDFDF",
            background: "white",
            marginBottom: "30px",
            borderRadius: "8px",
            padding: "56px",
          }}
        >
          {loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              <Spinner animation="border" role="status" size="sm" />
            </div>
          ) : !selectedValues["chart_type"] ? null : selectedValues[
              "chart_type"
            ].value === "bar" ? (
            selectedValues["flow_type"].value === "compare" ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" allowDuplicatedCategory={false} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {data?.map((itm, idx) =>
                    data?.[idx]?.[0]?.isPreceding ? (
                      <Bar
                        dataKey={data?.[idx]?.[0]?.label}
                        fill={
                          data?.[idx]?.[0]?.category === "reports"
                            ? "#9B64C6"
                            : data?.[idx]?.[0]?.category === "recommendations"
                            ? "#FF7A00"
                            : data?.[idx]?.[0]?.category === "sms"
                            ? "#4EAC9F"
                            : data?.[idx]?.[0]?.category === "emails"
                            ? "#DFE0EB"
                            : data?.[idx]?.[0]?.category === "notes"
                            ? "#00AEEF"
                            : ""
                        }
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                        barSize={40}
                        data={itm?.map((item) => ({
                          date: item.date,
                          [item.label]:
                            selectedValues["data_subset"]?.["reports"]
                              ?.value !== "charge"
                              ? item?.data?.[0].id__count
                              : item?.data?.[0]
                                  .imagine_metadata__charge_amount__sum,
                        }))}
                      />
                    ) : (
                      <Bar
                        dataKey={data?.[idx]?.[0]?.label}
                        fill={
                          data?.[idx]?.[0]?.category === "reports"
                            ? "#FEC400"
                            : data?.[idx]?.[0]?.category === "recommendations"
                            ? "#6FCF97"
                            : data?.[idx]?.[0]?.category === "sms"
                            ? "#00AEEF"
                            : data?.[idx]?.[0]?.category === "emails"
                            ? "#FF5F65"
                            : data?.[idx]?.[0]?.category === "notes"
                            ? "#9B64C6"
                            : ""
                        }
                        activeBar={<Rectangle fill="pink" stroke="blue" />}
                        barSize={40}
                        data={itm?.map((item) => ({
                          date: item.date,
                          [item.label]:
                            selectedValues["data_subset"]?.["reports"]
                              ?.value !== "charge"
                              ? item?.data?.[0].id__count
                              : item?.data?.[0]
                                  .imagine_metadata__charge_amount__sum,
                        }))}
                      />
                    )
                  )}
                </BarChart>
              </ResponsiveContainer>
            ) : selectedValues["data_type"]?.value === "reports" ||
              selectedValues["data_type"]?.value === "notes" ? (
              <ResponsiveContainer width="100%" height="100%">
                {selectedValues["data_subset"]?.value === "charge" ? (
                  <BarChart
                    width={500}
                    height={300}
                    data={data?.map((item) => ({
                      date: item.date,
                      charge_amount_sum:
                        item.data[0]?.imagine_metadata__charge_amount__sum,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip
                      formatter={(value) =>
                        `$${value?.toLocaleString("en-US")}`
                      }
                    />
                    <Legend />
                    <Bar
                      dataKey="charge_amount_sum"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      barSize={40}
                    />
                  </BarChart>
                ) : (
                  <BarChart
                    width={500}
                    height={300}
                    data={data?.map((item) => ({
                      date: item.date,
                      id__count: item.data[0].id__count,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="id__count"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      barSize={40}
                    />
                  </BarChart>
                )}
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={flattenData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {uniqueStatuses.map((status, index) => (
                    <Bar
                      key={index}
                      dataKey={status}
                      name={status}
                      barSize={40}
                      fill={
                        selectedValues["data_type"]?.value ===
                          "recommendations" &&
                        selectedValues["group_by"]
                          ?.map((itm) => itm.value)
                          .join(",") === "status"
                          ? recommendationStatusColors(status)
                          : selectedValues["data_type"]?.value ===
                            "recommendations"
                          ? randomColor(index)
                          : selectedValues["data_type"]?.value === "sms"
                          ? smsColors(status)
                          : emailColors(status)
                      }
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            )
          ) : selectedValues["chart_type"].value === "line" ? (
            selectedValues["flow_type"].value === "compare" ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" allowDuplicatedCategory={false} />
                  <YAxis />
                  <Tooltip />
                  <Legend iconType="plainline" />
                  {data?.map((itm, idx) =>
                    data?.[idx]?.[0]?.isPreceding ? (
                      <Line
                        type="monotone"
                        dataKey={data?.[idx]?.[0]?.label}
                        stroke={
                          data?.[idx]?.[0]?.category === "reports"
                            ? "#FEC400"
                            : data?.[idx]?.[0]?.category === "recommendations"
                            ? "#6FCF97"
                            : data?.[idx]?.[0]?.category === "sms"
                            ? "#00AEEF"
                            : data?.[idx]?.[0]?.category === "emails"
                            ? "#FF5F65"
                            : data?.[idx]?.[0]?.category === "notes"
                            ? "#9B64C6"
                            : ""
                        }
                        data={itm?.map((item) => ({
                          date: item.date,
                          [item.label]:
                            selectedValues["data_subset"]?.["reports"]
                              ?.value !== "charge"
                              ? item?.data?.[0].id__count
                              : item?.data?.[0]
                                  .imagine_metadata__charge_amount__sum,
                        }))}
                        strokeDasharray="10 10"
                        dot={false}
                      />
                    ) : (
                      <Line
                        type="monotone"
                        dataKey={data?.[idx]?.[0]?.label}
                        stroke={
                          data?.[idx]?.[0]?.category === "reports"
                            ? "#FEC400"
                            : data?.[idx]?.[0]?.category === "recommendations"
                            ? "#6FCF97"
                            : data?.[idx]?.[0]?.category === "sms"
                            ? "#00AEEF"
                            : data?.[idx]?.[0]?.category === "emails"
                            ? "#FF5F65"
                            : data?.[idx]?.[0]?.category === "notes"
                            ? "#9B64C6"
                            : ""
                        }
                        activeDot={{ r: 8 }}
                        data={itm?.map((item) => ({
                          date: item.date,
                          [item.label]:
                            selectedValues["data_subset"]?.["reports"]
                              ?.value !== "charge"
                              ? item?.data?.[0].id__count
                              : item?.data?.[0]
                                  .imagine_metadata__charge_amount__sum,
                        }))}
                      />
                    )
                  )}
                </LineChart>
              </ResponsiveContainer>
            ) : selectedValues["data_type"].value !== "reports" &&
              selectedValues["data_type"].value !== "notes" ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={flattenData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  {uniqueStatuses.map((status, index) => (
                    <Line
                      key={index}
                      dataKey={status}
                      name={status}
                      stroke={
                        selectedValues["data_type"]?.value ===
                          "recommendations" &&
                        selectedValues["group_by"]
                          ?.map((itm) => itm.value)
                          .join(",") === "status"
                          ? recommendationStatusColors(status)
                          : selectedValues["data_type"]?.value ===
                            "recommendations"
                          ? randomColor(index)
                          : selectedValues["data_type"]?.value === "sms"
                          ? smsColors(status)
                          : emailColors(status)
                      }
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                {selectedValues["data_subset"]?.value === "charge" ? (
                  <LineChart
                    width={500}
                    height={300}
                    data={data.map((item) => ({
                      date: item.date,
                      charge_amount_sum:
                        item.data[0].imagine_metadata__charge_amount__sum,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip
                      formatter={(value) =>
                        `$${value?.toLocaleString("en-US")}`
                      }
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="charge_amount_sum"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                ) : (
                  <LineChart
                    width={500}
                    height={300}
                    data={data.map((item) => ({
                      date: item.date,
                      id__count: item.data[0].id__count,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="id__count"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                )}
              </ResponsiveContainer>
            )
          ) : selectedValues["chart_type"].value === "pie" ? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={600} height={600}>
                <Pie
                  data={data}
                  cx="35%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={180}
                  fill="#8884d8"
                  dataKey="id__count"
                >
                  {data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  width={120}
                  height={140}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={legendWrapperStyle}
                  iconType={"circle"}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={600} height={600}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  dataKey="id__count"
                  startAngle={180}
                  endAngle={0}
                  data={data}
                  cy={240}
                  cx={"35%"}
                  outerRadius={220}
                  innerRadius={50}
                  fill="#8884d8"
                  label={renderCustomizedLabel}
                  labelLine={false}
                  onMouseEnter={onPieEnter}
                  animationDuration={0}
                  strokeWidth={0}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}-${activeIndex}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  width={120}
                  height={140}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={legendWrapperStyle}
                  iconType={"circle"}
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <button
          style={{
            background:
              selectedValues["chart_type"]?.value === "line" ||
              selectedValues["chart_type"]?.value === "bar"
                ? !title ||
                  !selectedValues["chart_type"] ||
                  !filterValue ||
                  !selectedValues["data_type"] ||
                  !selectedValues["frequency"] ||
                  !selectedValues["group_by"]
                  ? "#eeeeee"
                  : "#00AEEF"
                : !title ||
                  !selectedValues["chart_type"] ||
                  !selectedValues["data_type"]
                ? "#eeeeee"
                : "#00AEEF",

            color: "white",
            borderRadius: "4px",
            border: "none",
            height: "38px",
            fontWeight: 600,
            fontSize: "14px",
            width: "283px",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={
            selectedValues["chart_type"]?.value === "line" ||
            selectedValues["chart_type"]?.value === "bar"
              ? !title ||
                !selectedValues["chart_type"] ||
                !selectedValues["data_type"] ||
                !selectedValues["frequency"] ||
                !filterValue ||
                !selectedValues["group_by"] ||
                isLoading
              : !title ||
                !selectedValues["chart_type"] ||
                !selectedValues["data_type"] ||
                isLoading
          }
          onClick={handleFormSubmit}
        >
          {isLoading ? "Saving..." : "Save & Create"}
        </button>

        </div>
        
      </div>
    </Container>
  );
};

export default CustomChartsCreate;
