import React from 'react';
import { ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import MUISlider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Flex from '../../../components/_styles/Flex'

import '../../../components/Slider/MUISliderOverride.css';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

  
const theme = createMuiTheme({
  direction: 'rtl',
});


const Slider = withStyles({
  root: {
    color: '#00AEEF',
    height: 2,
    padding: '5px 0'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -10,
    marginLeft: -14,
    '&:focus,&:hover,&$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000'
    },
    width: 150,
  },
  track: {
    height: 3
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  }
})(MUISlider);

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    zIndex: 0, 
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useStyles();

  return (
    <Tooltip classes={{ popper: classes.customTooltip }} open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function valueLabelFormat(value) {
  return  <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', fontWeight:600, fontSize:'10px'}}>
    <div>{value}</div>
    <div>days</div>
  </div>;
}

function valueLabelFormatRtl(value) {
  return  <div style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', fontWeight:600, fontSize:'10px'}}>
   
   { value ===0? 'Today': 
   <>
   <div>{value} days</div>
    <div>ago</div>
   </>
   } 
   </div>
}


export default function RecoConfigSlider({ defaultValue, onChangeHandle, key, value, reverseOrientation=false, min=0, max=365}) {
  return (
    <Flex>
      <Flex.Col flex={2}>
        {
          reverseOrientation? 
          <ThemeProvider theme={theme}>
          <Slider
            min= {min}
            max= {max}
            key= {key}
            value={value}
            ValueLabelComponent={ValueLabelComponent}
            defaultValue={defaultValue ? defaultValue : 0}
            valueLabelDisplay="on"
            onChange={(e, val) => onChangeHandle(e, val)}
            getAriaValueText={valueLabelFormatRtl}
            valueLabelFormat={valueLabelFormatRtl}
          />
          </ThemeProvider>
          :
          <Slider
            min= {min}
            max= {max}
            key= {key}
            value={value}
            ValueLabelComponent={ValueLabelComponent}
            defaultValue={defaultValue ? defaultValue : 0}
            valueLabelDisplay="on"
            onChange={(e, val) => onChangeHandle(e, val)}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
          />
        }
  
      </Flex.Col>
    </Flex>
  );
}
