import React, { useState } from "react";
import styled from "styled-components";
import RecoConfigSlider from "../../RadAdmin/RecoConfigurationList/RecoSlider";
import { Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecoConfig,
  showAlert,
  getRecoConfig,
} from "../../../actions/radminUploads";
import RecoSwitcher from "../../../components/UI/ToggleSwitch/Switcher";
import { useEffect } from "react";
import EnableFeature from '../../../components/EnableFeature/EnableFeature';
const Card = styled.div`
  margin: 50px 0px;
  width: 90%;
  background: #ffffff;
  border-top: 1px solid #dfe0eb;
  border-right: 1px solid #dfe0eb;
  border-left: 1px solid #dfe0eb;
  box-sizing: border-box;
`;

const CardContainer = styled.div`
  padding: 20px 50px;
  background: ${(props) => props.color || "#FAFAFA"};
  border-bottom: 1px solid #dfe0eb;
  box-sizing: border-box;
`;

const CardTitle = styled.div`
  margin: 10px 20px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #252733;
`;

const PageTitleText = styled.h1`
  font-family: Roboto Condensed;
  font-style: bold;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.2px;
  color: #0C2945;
  margin-bottom:0px;
`;
const TitleText = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-bottom:10px;
`;

const Text = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;
const TextSwitch = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #8E8E8E;

`;

const RecoSlider = styled.div`
  width: 50%;
`;

const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:20px;
  justify-content:center;
`;

const SaveButtonContainer = styled.div`
  margin: -30px auto;
  background: #00AEEF;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items:center;
  cursor: pointer;
  margin-bottom: 30px;
  height:38px;
  width:709px;

`;

const SaveButton = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  height:38px;
  display:flex;
  align-items:center;
`;

const StyledButton = styled.button`
border: 2px solid ${({ active }) => (!active ? '#00AEEF' : '#B5B5B5')};
color: ${({ active }) => (!active ? '#00AEEF' : '#B5B5B5')};
background: ${({ active }) => (!active ? '#EFFBFF' : '#FFFFFF')};
min-width: 153px;
font-size: 14px;
font-weight: ${({ active }) => (!active ? 700 : 400)};
height: 26px;
cursor: pointer;
position: relative;

&:first-child {
  border-radius: 4px 0 0 4px;
  border-right:1px solid #00AEEF

}

&:last-child {
  border-radius: 0 4px 4px 0;
  border-left:1px solid  #00AEEF;
}
`

const RecoConfig = () => {
  const dispatch = useDispatch();
  const [conditional, setConditional] = useState(false);
  const [negated, setNegated] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [mammogramInEmail, setMammogramInEmail] = useState(false);
  const [impliedModality, setImpliedModality] = useState(false);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [unequalModRangeStart, setUnequalModRangeStart] = useState(0);
  const [unequalModRangeEnd, setUnequalModRangeEnd] = useState(0);
  const [defaultRangeStart, setDefaultRangeStart] = useState(0);
  const [defaultRangeEnd, setDefaultRangeEnd] = useState(0);
  const recoConfigData = useSelector((state) => state.radAdmin.recoConfig.data);
  const [inputValue, setInputValue] = useState('');
  const [validationError, setValidationError] = useState('');
  const [attributionValue, setAttributionValue] = useState('');
  const [attributionValidationError, setAttributionValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDefaultDate, setShowDefaultDate] = useState(false);



  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    const codes = inputValue.split(',').map((code) => code.trim());

    let error = '';

    let hasInvalidLength = false;
    let hasInvalidSeparator = false;

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      if (code && !code.match(/^\w{5}$/)) {
        hasInvalidLength = true;
      }
    }

    if (codes.some((code) => code.includes(' '))) {
      hasInvalidSeparator = true;
    }

    if (hasInvalidLength && hasInvalidSeparator) {
      error = '<span>Exam codes must be five digits long.</span><br/><span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>';
    } else if (hasInvalidLength) {
      error = '<span>Exam codes must be five digits long.</span>';
    } else if (hasInvalidSeparator) {
      error = '<span>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>';
    }

    setValidationError(error);
  };


  const handleAttributionInputChange = (event) => {
    const inputValue = event.target.value;
    setAttributionValue(inputValue);
    let error = '';
    if(inputValue>365){
      error = '<span>Value must be less than equal to 365</span>';
    } else if(inputValue<0){
      error = '<span>Value must be greater than equal to 0</span>';
    }

    setAttributionValidationError(error);
  };

  const handleConditionalChange = () => {
    setConditional(!conditional);
  };

  const handleNegatedChange = () => {
    setNegated(!negated);
  };

  
  const handleImpliedModalityChange = () => {
    setImpliedModality(!impliedModality);
  };

  const handlePointerChange = () => {
    setPointer(!pointer);
  };

  const handleMammoChange = () => {
    setMammogramInEmail(!mammogramInEmail);
  };

  const handleStartRangeChange = (val) => {
    setRangeStart(val);
  };

  const handleEndRangeChange = (val) => {
    setRangeEnd(val);
  };
  const handleUnequalStartRangeChange = (val) => {
    setUnequalModRangeStart(val);
  };

  const handleUnequalEndRangeChange = (val) => {
    setUnequalModRangeEnd(val);
  };

  const handleDefaultStartRangeChange = (val) => {
    setDefaultRangeStart(val);
  };

  const handleDefaultEndRangeChange = (val) => {
    setDefaultRangeEnd(val);
  };

  const handleShowDefaultDateChange = () => {
    setShowDefaultDate(!showDefaultDate);
  };


  useEffect(() => {
    dispatch(getRecoConfig());
  }, []);

  useEffect(() => {
    if (recoConfigData) {
      const {
        negated,
        conditional,
        rangeStart,
        rangeEnd,
        unequalModRangeStart,
        unequalModRangeEnd,
        nlp_post_impression,
        impliedModality,
        excludedExamCodes,
        mammogramInEmails,
        attributionValue,
        defaultRangeStart,
        defaultRangeEnd,
        showDefaultDate
      } = recoConfigData;
      setConditional(conditional);
      setNegated(negated);
      setRangeStart(rangeStart);
      setRangeEnd(rangeEnd);
      setUnequalModRangeStart(unequalModRangeStart);
      setUnequalModRangeEnd(unequalModRangeEnd);
      setPointer(nlp_post_impression);
      setMammogramInEmail(mammogramInEmails);
      setImpliedModality(impliedModality);
      setInputValue(excludedExamCodes?.join(','));
      setAttributionValue(attributionValue);
      setDefaultRangeStart(defaultRangeStart);
      setDefaultRangeEnd(defaultRangeEnd);
      setShowDefaultDate(showDefaultDate)
    }
  }, [recoConfigData]);

  const handleSaveChange = () => {
    if(validationError && inputValue){
      dispatch(
        showAlert('danger', 'Error', 'Validation error in exclude exam code')
      );
      return
    }
    if(attributionValidationError && attributionValue){
      dispatch(
        showAlert('danger', 'Error', 'Validation error in attribution window adherence')
      );
      return
    }
    if (rangeStart < rangeEnd) {
      setLoading(true);
      const recoConfig = {
        conditional,
        negated,
        rangeStart,
        rangeEnd,
        unequalModRangeStart,
        unequalModRangeEnd,
        provider: recoConfigData?.provider,
        pointer,
        impliedModality,
        examCodes:inputValue,
        mammogramInEmail,
        attributionValue: +attributionValue,
        defaultRangeStart:defaultRangeStart,
        defaultRangeEnd: defaultRangeEnd,
        showDefaultDate: showDefaultDate
      };
      window.analytics.track("Recommendation-Configuration-Submit",{...recoConfig})
      dispatch(updateRecoConfig(recoConfigData.id, recoConfig))
      .then(res => {
        if(res)
        dispatch(getRecoConfig());
        setLoading(false);
      })
    } else {
      dispatch(
        showAlert('danger', 'Error', "RangeStart should be less than RangeEnd.")
      );
      setLoading(false);
    }
  };

  return (
    <>
    <PageTitleText>Recommendation Configuration</PageTitleText>
      <Card>
        <CardContainer color="#FFFFFF">
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Conditional</TitleText>
              <Text>
                This configures a recommendation that is flagged as being
                conditional to be shown and used in the Follow-up list and
                tracking
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center",  justifyContent:'center' }}>
              <StyledButton
                active={conditional}
                onClick={() => {
                 handleConditionalChange()
                }}
              >
                Don't Show
              </StyledButton>
              <StyledButton
                active={!conditional}
                onClick={() => {
                  handleConditionalChange()
                }
              }
              >
                Show Conditionals
              </StyledButton>
            </div>
           
            </Col>
          </Row>
        </CardContainer>

        <CardContainer>
          <Row style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Negated</TitleText>
              <Text>
                This configures a recommendation that is flagged as being
                negated to be shown and used in the Follow-up list and tracking
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center", justifyContent:'center' }}>
              <StyledButton
                active={negated}
                onClick={() => {
                 handleNegatedChange()
                }}
              >
                Don't Show
              </StyledButton>
              <StyledButton
                active={!negated}
                onClick={() => {
                  handleNegatedChange()
                }
              }
              >
                Show Negated
              </StyledButton>
            </div>
           
            </Col>
          </Row>
        </CardContainer>

        <CardContainer color="#FFFFFF">
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Pointer</TitleText>
              <Text>
                This configures a recommendation to be extracted from just the
                impression or the entire report text body
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center",  justifyContent:'center' }}>
              <StyledButton
                active={pointer}
                onClick={() => {
                 handlePointerChange()
                }}
              >
              Entire Report
              </StyledButton>
              <StyledButton
                active={!pointer}
                onClick={() => {
                  handlePointerChange()
                }
              }
              >
                Impression Only
              </StyledButton>
            </div>
            
            </Col>
          </Row>
        </CardContainer>

        <CardContainer>
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Weekly Email Mammo Recommendation Filter</TitleText>
              <Text>
               show mammogram recommendations in weekly email 
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center",  justifyContent:'center' }}>
              <StyledButton
                active={mammogramInEmail}
                onClick={() => {
                 handleMammoChange()
                }}
              >
                Don't Show
              </StyledButton>
              <StyledButton
                active={!mammogramInEmail}
                onClick={() => {
                  handleMammoChange()
                }
              }
              >
                Show Mammo
              </StyledButton>
            </div>
             
            </Col>
          </Row>
        </CardContainer>

        


        <CardContainer color="#FFFFFF" >
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>No Timeframe Imputation</TitleText>
              <Text>
                This configures a recommendation default timeframe that doesn't
                have a timeframe mentioned to have a specific value instead
              </Text>
            </Col>
            <Col>
              <ToggleSwitchWrapper style={{ marginTop: "20px" }}>
              <TextSwitch>Range Start</TextSwitch>
                <RecoSlider>
                  <RecoConfigSlider
                    value={[rangeStart,rangeEnd]}
                    defaultValue={rangeStart}
                    key="range-start"
                    onChangeHandle={(e, val) => {
                      handleStartRangeChange(val[0])
                      handleEndRangeChange(val[1])
                    }}
                  />
                </RecoSlider>
                <TextSwitch>Range End</TextSwitch>
              </ToggleSwitchWrapper>

             
            </Col>
          </Row>
        </CardContainer>
        <CardContainer >
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>No Timeframe Unequal Modalities Imputation</TitleText>
              <Text>
                This configures a recommendation default timeframe that doesn't
                have a timeframe mentioned and where the report modality and the
                recommendation modality are different to have a specific value
                instead
              </Text>
            </Col>
            <Col>
              <ToggleSwitchWrapper style={{ marginTop: "20px" }}>
              <TextSwitch>Range Start</TextSwitch>
                <RecoSlider>
                  <RecoConfigSlider
                    value={[unequalModRangeStart,unequalModRangeEnd]}
                    defaultValue={unequalModRangeStart}
                    key="range-start"
                    onChangeHandle={(e, val) =>{
                      handleUnequalStartRangeChange(val[0])
                      handleUnequalEndRangeChange(val[1])
                    }
                    }
                  />
                </RecoSlider>
                <TextSwitch>Range End</TextSwitch>
              </ToggleSwitchWrapper>

         
            </Col>
          </Row>
        </CardContainer>
        <CardContainer color="#FFFFFF">
          <Row style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Default Date</TitleText>
              <Text>
               Toggle this on or off to use a Default Date Range on the Follow-Up List. Set the default date range for the initial exam date in the slider below.
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center", justifyContent:'center' }}>
              <StyledButton
                active={showDefaultDate}
                onClick={() => {
                 handleShowDefaultDateChange()
                }}
              >
                Don't Show
              </StyledButton>
              <StyledButton
                active={!showDefaultDate}
                onClick={() => {
                  handleShowDefaultDateChange()
                }
              }
              >
                Show Default Date
              </StyledButton>
            </div>
           
            </Col>
          </Row>
        </CardContainer>
        <CardContainer>
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Default Date Range on Follow Up List and Overview Screen</TitleText>
              <Text>
               Default date parameters for analytics and follow up list date range for initial exam date
              </Text>
            </Col>
            <Col>
              <ToggleSwitchWrapper style={{ marginTop: "20px" }}>
              <TextSwitch>Range Start</TextSwitch>
                <RecoSlider>
                  <RecoConfigSlider
                    value={[defaultRangeStart, defaultRangeEnd]}
                    defaultValue={defaultRangeStart}
                    key="range-start"
                    onChangeHandle={(e, val) =>{
                      handleDefaultStartRangeChange(val[0])
                      handleDefaultEndRangeChange(val[1])
                    }
                    }
                    reverseOrientation={true}
                    min={0}
                    max={365}
                  />
                </RecoSlider>
                <TextSwitch>Range End</TextSwitch>
              </ToggleSwitchWrapper>

         
            </Col>
          </Row>
        </CardContainer>

        <CardContainer color="#FFFFFF">
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Missing Modality Imputation</TitleText>
              <Text>
                Sometimes the recommendation does not specify a paticular type
                of exam for a follow-up exam. In these cases, you have the
                ability to have us impute these values with the original exam's
                values that created the recommendation
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", alignItems: "center",  justifyContent:'center' }}>
              <StyledButton
                active={impliedModality}
                onClick={() => {
                 handleImpliedModalityChange()
                }}
              >
               keep the values empty
              </StyledButton>
              <StyledButton
                active={!impliedModality}
                onClick={() => {
                  handleImpliedModalityChange()
                }
              }
              >
                Fill it in with the originating exam values
              </StyledButton>
            </div>
             
            
            </Col>
          </Row>
        </CardContainer>

        <CardContainer>
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Exclude Exam Codes</TitleText>
              <Text>
                Use the text box to the right to create a comma-separated list
                of exam codes to be excluded from recommendation tracking
                {" "}
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", flexDirection:'column',  alignItems: "center",  justifyContent:'center' }}>
              <div>
              <textarea

style={{resize:'none', border:validationError? '1px solid #B40000':'1px solid #D2D3D7', borderRadius:'4px', padding:'10px', height:'65px', width:'486px'}}
value={inputValue}
onChange={handleInputChange}
placeholder="e.g. 00000,00000"
/>
{
!inputValue && <div style={{display:'flex', flexDirection:'column'}}>
  <span style={{fontSize:'14px', color:'#828282', fontWeight:400}}>Exam codes must be five digits long.</span>
  <span style={{fontSize:'14px', color:'#828282', fontWeight:400}}>Commas must be used to separate multiple exam codes (e.g. 00000,00000).</span>
</div>
}
{inputValue&& validationError && <div style={{ color: '#B40000', fontSize:'14px', fontWeight:400}} dangerouslySetInnerHTML={{__html:validationError}}></div>}
              </div>
   
          </div>
            </Col>
          </Row>
        </CardContainer>
        <CardContainer color="#FFFFFF">
          <Row  style={{alignItems:'center', gap:'147px'}}>
            <Col>
              <TitleText>Attribution Window Adherence</TitleText>
              <Text>
              Use the text box to the right to set an attribution window to count recommendations completed after a message is sent in order to count the reports and revenue that came from a message
                {" "}
              </Text>
            </Col>
            <Col>
            <div style={{ display: "flex", flexDirection:'column',  alignItems: "center",  justifyContent:'center' }}>
              <div style={{width:'486px'}}>
                <input
                type="number"
                min={0}
                style={{border:attributionValidationError? '1px solid #B40000':'1px solid #D2D3D7', borderRadius:'4px', padding:'10px', height:'37px', width:'66px', marginRight:'15px'}}
                value={attributionValue}
                onChange={handleAttributionInputChange}
                />
                <span style={{fontSize:'14px', color:'#828282', fontWeight:400}}>days</span>
            
                {attributionValue&& attributionValidationError && <div style={{ color: '#B40000', fontSize:'14px', fontWeight:400}} dangerouslySetInnerHTML={{__html:attributionValidationError}}></div>}
              </div>
   
          </div>
            </Col>
          </Row>
        </CardContainer>
      </Card>
      <SaveButtonContainer onClick={handleSaveChange}>
        <SaveButton variant="contained" color="secondary">
        {loading ? <Spinner animation="border" role="status" size="sm" />  : 'Save Changes' }  
        </SaveButton>
      </SaveButtonContainer>
    </>
  );
};

 export const RecoConfigWithFeature = props => {
  return (
    <EnableFeature featureName={'enable-reco-config'} {...props}>
      <RecoConfig />
    </EnableFeature>
  );
};

export default RecoConfig;
