import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useHistory } from "react-router";
import { ReactComponent as CustomChartsIcon } from "../../../assets/no-custom-charts.svg";
import {
  getCustomChartData,
  getSavedCustomChartData,
} from "../../../actions/radminCustomChartData";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import {
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Rectangle,
  Sector,
} from "recharts";
import { Spinner } from "react-bootstrap";
import {
  Button,
  Container,
  LegendList,
  LegendListContainer,
  LegendListItem,
  SelectedItem,
  SelectedItemContainer,
  legendWrapperStyle,
  randomColor,
} from "./Constants";
import {
  emailColors,
  recommendationStatusColors,
  smsColors,
  COLORS,
} from "./Constants";
import EditModal from "./EditChart";
import moment from "moment";
import { showAlert } from "../../../actions/radminUploads";
import { CSVLink } from "react-csv";
import styled from "styled-components";

const editIcon = `${process.env.PUBLIC_URL}/${"Edit.svg"}`;

const StyledTableContainer = styled.table`
& table:last-child tbody tr:last-child {
border-bottom:none;
}
`;

const StyledCsvBtn = styled(CSVLink)`
  text-decoration:none!important;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:20px;
  background: #00aeef;
  color: white;
  border-radius: 4px;
  border: 2px solid #00aeef;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  width: 183px;

  &:hover {
    color: #00aeef;
    background: transparent;
  }
`


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";

  return (
    <g>
      <text
        x={cx}
        y={cy - 30}
        dy={8}
        fill="#0C2945"
        textAnchor={"middle"}
        dominantBaseline="central"
        style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 900 }}
      >
        {percentage}
      </text>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={"#A6ACBE"}
        style={{ fontSize: "10px", lineHeight: "13px", fontWeight: 600 }}
      >
        {payload?.status || payload?.gender}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let percentage =
    (percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : "";
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {percentage}
      </text>
    </g>
  );
};

const CustomCharts = () => {
  const history = useHistory();
  const [customCharts, setCustomCharts] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [editing, setEditing] = useState({ visible: false, data: {} });
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Custom Charts")
    ?.permissions.find(
      (itm) => itm.codename === "add_customcharts"
    )?.is_authorized;

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSavedCustomChartData(1)).then((res) => {
      if(res){
      setCustomCharts(res.results);
      if (res.next) {
        setNextPage(2);
      }
      }
     
    });
  }, []);

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 5 }}>
                <input
                  type="radio"
                  checked={props.isSelected}
                  onChange={() => null}
                  style={{ cursor: "pointer" }}
                />{" "}
              </span>
              <label style={{ cursor: "pointer", marginBottom: 0 }}>
                {props.label}
              </label>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditing({ visible: true, data: props.data });
                }}
                src={editIcon}
              />
            </div>
          </div>
        </components.Option>
      </>
    );
  };

  const handleChartSelect = (selectedOption) => {
    setSelectedChart(selectedOption);
    window.analytics.track("Custom-Chart-Option-Click", { ...selectedOption });
    if (selectedOption) {
      setLoadingGraph(true);
      const { selectedValues, filterValue } = selectedOption;
      if (
        filterValue?.value === "Custom date range" &&
        selectedValues.startDate &&
        selectedValues.endDate
      ) {
        dispatch(
          getCustomChartData(
            selectedValues,
            selectedValues?.startDate,
            selectedValues?.endDate,
            filterValue
          )
        )
          .then((res) => setData(res))
          .catch((err) => Sentry.captureException(err))
          .finally(() => {
            setLoadingGraph(false);
          });
      } else {
        dispatch(
          getCustomChartData(
            selectedValues,
            selectedValues?.selectedDate,
            moment(new Date()).format("YYYY-MM-DD"),
            filterValue
          )
        )
          .then((res) => setData(res))
          .catch((err) => Sentry.captureException(err))
          .finally(() => {
            setLoadingGraph(false);
          });
      }
    }
  };

  const chartOptions =
    customCharts.length > 0 &&
    customCharts?.map((chart) => ({
      value: chart?.id,
      label: chart?.title,
      selectedValues: JSON.parse(chart?.filters),
      filterValue: JSON.parse(chart?.filters)?.timeframe,
    }));

  let flattenData = [];
  let uniqueStatuses = [];

  if (
    (selectedChart?.selectedValues["data_type"]?.value === "recommendations" ||
      selectedChart?.selectedValues["data_type"]?.value === "sms" ||
      selectedChart?.selectedValues["data_type"]?.value === "emails") &&
    data?.length > 0
  ) {
    flattenData = data?.map((item) => {
      const obj = {};
      if (item?.data?.length > 0) {
        item.data.forEach((d) => {
          if (d.status && d.modality && d.anatomy) {
            obj[d.status + " " + d.modality + " " + d.anatomy] = d["id__count"];
          } else if (d.modality && d.anatomy) {
            obj[d.modality + " " + d.anatomy] = d["id__count"];
          } else if (d.status && d.modality) {
            obj[d.status + " " + d.modality] = d["id__count"];
          } else if (d.status && d.anatomy) {
            obj[d.status + " " + d.anatomy] = d["id__count"];
          } else {
            obj[d.status || d.modality || d.anatomy] = d["id__count"];
          }
        });
      }

      return { date: item.date, ...obj };
    });

    uniqueStatuses = [
      ...new Set(
        data?.flatMap((item) =>
          item?.data?.map((d) =>
            d.status && d.modality && d.anatomy
              ? d.status + " " + d.modality + " " + d.anatomy
              : d.status && d.modality
              ? d.status + " " + d.modality
              : d.modality && d.anatomy
              ? d.modality + " " + d.anatomy
              : d.anatomy && d.status
              ? d.status + " " + d.anatomy
              : d.status || d.modality || d.anatomy
          )
        )
      ),
    ];
  }

  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState();

  const [loadingGraph, setLoadingGraph] = useState(false);

  const loadMoreOptions = () => {
    if (nextPage && !loadingMore) {
      setLoadingMore(true);
      dispatch(getSavedCustomChartData(nextPage)).then((res) => {
        setLoadingMore(false);
        if(res){
        setCustomCharts((prev) => [...prev, ...res.results]);
        if (res.next) {
          setNextPage((prev) => prev + 1);
        } else {
          setNextPage();
        }
      }
      });
    }
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <LegendListContainer>
        <LegendList>
          {payload
            .sort((a, b) => b.payload.id__count - a.payload.id__count)
            .map((entry, index) => (
              <LegendListItem
                key={index}
                color={COLORS[index % COLORS.length]}
              >{`${
                selectedChart?.selectedValues["data_type"]?.value ===
                  "radiologists" ||
                selectedChart?.selectedValues["data_type"]?.value ===
                  "referrers"
                  ? entry.payload["status"].replaceAll("_", " ")
                  : entry.payload[
                      selectedChart?.selectedValues["data_subset"]?.value
                    ]
              } - ${entry.payload.id__count}`}</LegendListItem>
            ))}
        </LegendList>
      </LegendListContainer>
    );
  };

  const chips =
    selectedChart?.selectedValues?.["flow_type"]?.value === "compare"
      ? selectedChart?.selectedValues &&
        Object.keys(selectedChart?.selectedValues)
          .filter(
            (itm) =>
              itm === "additional" ||
              itm === "data_type" ||
              itm === "data_subset"
          )
          ?.map((itm) => {
            if (itm === "data_type") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                arr.push({
                  value: [
                    selectedChart?.selectedValues?.["data_type"][i].value,
                  ],
                  label: itm,
                });
              }
              return arr;
            }
            if (itm === "data_subset") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                arr.push({
                  value:
                    selectedChart?.selectedValues?.["data_subset"][
                      selectedChart?.selectedValues?.["data_type"][i].value
                    ]?.value,
                  label: itm,
                });
              }
              return arr;
            }
            if (itm === "additional") {
              let arr = [];
              for (
                let i = 0;
                i < selectedChart?.selectedValues?.["data_type"].length;
                i++
              ) {
                for (const [key, value] of Object.entries(
                  selectedChart?.selectedValues?.additional[
                    selectedChart.selectedValues.data_type[i].value
                  ]
                ) ?? {}) {
                  if (
                    value &&
                    key !== "report__patient__date_of_birth__gte" &&
                    key !== "report__patient__date_of_birth__lte"
                  ) {
                    if (Array.isArray(value)) {
                      value.map((itm) =>
                        arr.push({
                          value:
                            itm.category === "radiologist"
                              ? itm.label
                              : itm.value,
                          label:
                            itm.category === "radiologist"
                              ? "Radiologist"
                              : key,
                        })
                      );
                    } else {
                      arr.push({
                        value: value.value,
                        label: key,
                      });
                    }
                  }
                }
              }

              const filteredRecords = arr.filter(
                (record) =>
                  ![
                    "modality_type",
                    "anatomy_type",
                    "report__modality_type",
                  ].includes(record.label)
              );

              function getTypeValue(type) {
                const typeRecord = arr.find((record) => record.label === type);
                return typeRecord ? typeRecord.value : null;
              }

              const combinedStrings = filteredRecords.map((record) => {
                let value = record.value;
                if (
                  record.label === "modality" &&
                  getTypeValue("modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("modality_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "anatomy" &&
                  getTypeValue("anatomy_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("anatomy_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "report__modality" &&
                  getTypeValue("report__modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("report__modality_type") === false
                        ? "Is Not "
                        : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "initial_exam_date_gte" ||
                  record.label === "initial_exam_date_lte" ||
                  record.label === "nlp_range_start_gte" ||
                  record.label === "nlp_range_start_lte" ||
                  record.label === "nlp_range_end_gte" ||
                  record.label === "nlp_range_end_lte"
                ) {
                  value = moment(value).format("YYYY-MM-DD");
                }
                return { label: record.label, value: value };
              });

              return combinedStrings;
            }
          })
          .flat(10)
      : selectedChart?.selectedValues &&
        Object.keys(selectedChart?.selectedValues)
          .filter(
            (itm) =>
              itm === "additional" ||
              itm === "data_type" ||
              itm === "data_subset"
          )
          ?.map((itm) => {
            if (itm === "data_type" || itm === "data_subset") {
              return {
                value: selectedChart?.selectedValues[itm]?.value ==="recommendations-pie"?'Recommendations': selectedChart?.selectedValues[itm]?.value ==="modality,anatomy"? "Modality & Anatomy" :selectedChart?.selectedValues[itm]?.value,
                label: itm,
              };
            }
            if (itm === "additional") {
              let arr = [];
              for (const [key, value] of Object.entries(
                selectedChart?.selectedValues?.additional[
                  selectedChart.selectedValues.data_type.value
                ] ?? {}
              )) {
                if (
                  value &&
                  key !== "report__patient__date_of_birth__gte" &&
                  key !== "report__patient__date_of_birth__lte"
                ) {
                  if (Array.isArray(value)) {
                    value.map((itm) =>
                      arr.push({
                        value:
                          itm.category === "radiologist"
                            ? itm.label
                            : itm.value,
                        label:
                          itm.category === "radiologist" ? "Radiologist" : key,
                      })
                    );
                  } else {
                    arr.push({
                      value: value.value,
                      label: key,
                    });
                  }
                }
              }

              const filteredRecords = arr.filter(
                (record) =>
                  ![
                    "modality_type",
                    "anatomy_type",
                    "report__modality_type",
                  ].includes(record.label)
              );

              function getTypeValue(type) {
                const typeRecord = arr.find((record) => record.label === type);
                return typeRecord ? typeRecord.value : null;
              }

              const combinedStrings = filteredRecords.map((record) => {
                let value = record.value;

                if (
                  record.label === "modality" &&
                  getTypeValue("modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("modality_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "anatomy" &&
                  getTypeValue("anatomy_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("anatomy_type") === false ? "Is Not " : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "report__modality" &&
                  getTypeValue("report__modality_type") !== null
                ) {
                  value =
                    `${
                      getTypeValue("report__modality_type") === false
                        ? "Is Not "
                        : ""
                    }` + capitalizeWords(value);
                } else if (
                  record.label === "initial_exam_date_gte" ||
                  record.label === "initial_exam_date_lte" ||
                  record.label === "nlp_range_start_gte" ||
                  record.label === "nlp_range_start_lte" ||
                  record.label === "nlp_range_end_gte" ||
                  record.label === "nlp_range_end_lte"
                ) {
                  value = moment(value).format("YYYY-MM-DD");
                }
                return { label: record.label, value: value };
              });

              return combinedStrings;
            }
          })
          .flat(10);

  function capitalizeWords(str) {
    const words = str?.split(/[_\s]+/);

    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const capitalizedString = capitalizedWords?.join(" ");

    return capitalizedString;
  }

  const prepareCSVData = () => {
    let csvData = [];

    if(selectedChart?.selectedValues["chart_type"]?.value ===
      "pie" ||
      selectedChart?.selectedValues["chart_type"]?.value ===
        "gauge"){
    csvData.push(['Status', 'Count']);

    data.forEach((status) => {
      let statusLabel = selectedChart?.selectedValues["data_type"]?.value ===
        "radiologists" ||
        selectedChart?.selectedValues["data_type"]?.value ===
        "referrers"
          ? status["status"].replaceAll("_", " ")
          : status[selectedChart?.selectedValues["data_subset"]?.value];

      if (statusLabel) {
        let row = [statusLabel, status?.id__count];
        csvData.push(row);
      }
    });
  } else if (
      (selectedChart?.selectedValues["chart_type"]?.value === "bar" ||
        selectedChart?.selectedValues["chart_type"]?.value === "line") &&
      selectedChart?.selectedValues["flow_type"]?.value === "compare"
    ) {
      data.forEach((itm, idx) => {
        let headers = [data?.[idx]?.[0]?.label];
        itm.forEach((item) => {
          headers.push(
            data?.[idx]?.[0]?.isPreceding ? item.prevDate : item.date
          );
        });
        csvData.push(headers);
  
        let row = [
          selectedChart?.selectedValues["data_subset"]?.value === "charge"
            ? "Revenue"
            : "Count"
        ];
        itm.forEach((item) => {
          row.push(
            selectedChart?.selectedValues["data_subset"]?.value === "charge"
              ? `$${
                  item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString(
                    "en-US"
                  ) || '0'
                }`
              : item.data?.[0]?.id__count || '0'
          );
        });
        csvData.push(row);
      });
    } else if (
      (selectedChart?.selectedValues["chart_type"]?.value === "bar" ||
        selectedChart?.selectedValues["chart_type"]?.value === "line") &&
      selectedChart?.selectedValues["flow_type"]?.value === "individual" &&
      (selectedChart?.selectedValues["data_type"]?.value === "reports" ||
        selectedChart?.selectedValues["data_type"]?.value === "notes")
    ) {
      let headers = ['Reports'];
      data.forEach((item) => {
        headers.push(item.date); 
      });
      csvData.push(headers);
  
      let row = [
        selectedChart?.selectedValues["data_subset"]?.value === "charge"
          ? "Revenue"
          : "Count"
      ];
      data.forEach((item) => {
        row.push(
          selectedChart?.selectedValues["data_subset"]?.value === "charge"
            ? item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString("en-US") || '0'
            : item.data?.[0]?.id__count || '0'
        );
      });
      csvData.push(row);
  
    } else {
      let headers = ['Status'];
      flattenData.forEach((item) => {
        headers.push(item.date);  
      });
      csvData.push(headers);
  
      uniqueStatuses.forEach((status) => {
        let row = [status];
        flattenData.forEach((item) => {
          row.push(item[status] || '0'); 
        });
        csvData.push(row);
      });
    }

    return csvData;
  };
  return (
    <Container
      isTimeGraph={
        selectedChart?.selectedValues["chart_type"]?.value === "bar" ||
        selectedChart?.selectedValues["chart_type"]?.value === "line"
      }
      style={{ padding: "22px 33px 20px 20px", height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <h1
          style={{
            fontFamily: "Roboto Condensed",
            fontWeight: 700,
            fontSize: "36px",
            color: "#0C2945",
            marginBottom: "0px",
          }}
        >
          Custom Charts
        </h1>
        <Button
          onClick={() => {
            if ((permission && addPermission) || !permission) {
              history.push(`/auth/radAdmin/custom/charts/create`);
              window.analytics.track("Custom-Charts-Create-Click");
            } else {
              dispatch(
                showAlert(
                  "danger",
                  "Error",
                  `You don't have permission to create custom chart`
                )
              );
            }
          }}
        >
          Create Custom Chart
        </Button>
      </div>

      {customCharts?.length === 0 ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CustomChartsIcon />
        </div>
      ) : (
        <div
          style={{
            minHeight: "795px",
            background: "white",
            marginBottom: "30px",
            border: "1px solid #DFDFDF",
            borderRadius: "8px",
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "10px",
            }}
          >
            <label
              htmlFor="customChartDropdown"
              style={{
                marginRight: "10px",
                fontWeight: 700,
                fontSize: "14px",
                color: "#6D6E6E",
              }}
            >
              SAVED CUSTOM CHARTS
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ minWidth: "350px" }}>
                <Select
                  components={{ Option }}
                  id="customChartDropdown"
                  options={chartOptions}
                  value={selectedChart}
                  onChange={handleChartSelect}
                  isLoading={loadingMore}
                  onMenuScrollToBottom={loadMoreOptions}
                  isClearable={true}
                />
              </div>

              {selectedChart && (
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  {chips?.map((itm) => {
                    return (
                      <SelectedItemContainer>
                        <SelectedItem>
                          {capitalizeWords(itm?.label)} :{" "}
                          {capitalizeWords(String(itm?.value))}
                        </SelectedItem>
                      </SelectedItemContainer>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {loadingGraph ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              <Spinner animation="border" role="status" size="sm" />
            </div>
          ) : (
            customCharts.length > 0 &&
            selectedChart && (
              <>
                <h4 style={{ textAlign: "center" }}>{selectedChart?.label}</h4>
                <div style={{ height: "400px", marginBottom: "30px" }}>
                  {!selectedChart?.selectedValues[
                    "chart_type"
                  ] ? null : selectedChart?.selectedValues["chart_type"]
                      ?.value === "bar" ? (
                    selectedChart?.selectedValues["flow_type"]?.value ===
                    "compare" ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            allowDuplicatedCategory={false}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {data?.map((itm, idx) =>
                            data?.[idx]?.[0]?.isPreceding ? (
                              <Bar
                                dataKey={data?.[idx]?.[0]?.label}
                                fill={
                                  data?.[idx]?.[0]?.category === "reports"
                                    ? "#9B64C6"
                                    : data?.[idx]?.[0]?.category ===
                                      "recommendations"
                                    ? "#FF7A00"
                                    : data?.[idx]?.[0]?.category === "sms"
                                    ? "#4EAC9F"
                                    : data?.[idx]?.[0]?.category === "emails"
                                    ? "#DFE0EB"
                                    : data?.[idx]?.[0]?.category === "notes"
                                    ? "#00AEEF"
                                    : ""
                                }
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                                barSize={40}
                                data={itm?.map((item) => ({
                                  date: item.date,
                                  [item.label]:
                                    selectedChart?.selectedValues[
                                      "data_subset"
                                    ]?.["reports"]?.value !== "charge"
                                      ? item?.data?.[0].id__count
                                      : item?.data?.[0]
                                          .imagine_metadata__charge_amount__sum,
                                }))}
                              />
                            ) : (
                              <Bar
                                dataKey={data?.[idx]?.[0]?.label}
                                fill={
                                  data?.[idx]?.[0]?.category === "reports"
                                    ? "#FEC400"
                                    : data?.[idx]?.[0]?.category ===
                                      "recommendations"
                                    ? "#6FCF97"
                                    : data?.[idx]?.[0]?.category === "sms"
                                    ? "#00AEEF"
                                    : data?.[idx]?.[0]?.category === "emails"
                                    ? "#FF5F65"
                                    : data?.[idx]?.[0]?.category === "notes"
                                    ? "#9B64C6"
                                    : ""
                                }
                                activeBar={
                                  <Rectangle fill="pink" stroke="blue" />
                                }
                                barSize={40}
                                data={itm?.map((item) => ({
                                  date: item.date,
                                  [item.label]:
                                    selectedChart?.selectedValues[
                                      "data_subset"
                                    ]?.["reports"]?.value !== "charge"
                                      ? item?.data?.[0].id__count
                                      : item?.data?.[0]
                                          .imagine_metadata__charge_amount__sum,
                                }))}
                              />
                            )
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : selectedChart?.selectedValues["data_type"]?.value ===
                        "reports" ||
                      selectedChart?.selectedValues["data_type"]?.value ===
                        "notes" ? (
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <BarChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data?.[0]
                                  ?.imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Bar
                              dataKey="charge_amount_sum"
                              fill="#8884d8"
                              activeBar={
                                <Rectangle fill="pink" stroke="blue" />
                              }
                              barSize={40}
                            />
                          </BarChart>
                        ) : (
                          <BarChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              id__count: item.data?.[0]?.id__count,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="id__count"
                              fill="#8884d8"
                              activeBar={
                                <Rectangle fill="pink" stroke="blue" />
                              }
                              barSize={40}
                            />
                          </BarChart>
                        )}
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={flattenData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {uniqueStatuses.map((status, index) => (
                            <Bar
                              key={index}
                              dataKey={status}
                              name={status}
                              barSize={40}
                              fill={
                                selectedChart?.selectedValues["data_type"]
                                  ?.value === "recommendations" &&
                                selectedChart?.selectedValues["group_by"]
                                  ?.map((itm) => itm?.value)
                                  .join(",") === "status"
                                  ? recommendationStatusColors(status)
                                  : selectedChart?.selectedValues["data_type"]
                                      ?.value === "recommendations"
                                  ? randomColor(index)
                                  : selectedChart?.selectedValues["data_type"]
                                      ?.value === "sms"
                                  ? smsColors(status)
                                  : emailColors(status)
                              }
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    )
                  ) : selectedChart?.selectedValues["chart_type"]?.value ===
                    "line" ? (
                    selectedChart?.selectedValues["flow_type"]?.value ===
                    "compare" ? (
                      // !selectedValues["data_type"]?.includes("reports")  &&
                      // !selectedValues["data_type"]?.includes("notes") ? (
                      //   <ResponsiveContainer width="100%" height="100%">
                      //     <LineChart
                      //       width={500}
                      //       height={300}
                      //       data={flattenData}
                      //       margin={{
                      //         top: 5,
                      //         right: 30,
                      //         left: 20,
                      //         bottom: 5,
                      //       }}
                      //     >
                      //       <CartesianGrid strokeDasharray="3 3" />
                      //       <XAxis dataKey="date" />
                      //       <YAxis />
                      //       <Tooltip />
                      //       <Legend />

                      //       {uniqueStatuses.map((status, index) => (
                      //         <Line
                      //           key={index}
                      //           dataKey={status}
                      //           name={status}
                      //           stroke={
                      //             selectedValues["data_type"]?.value ===
                      //               "recommendations" &&
                      //             selectedValues["group_by"]
                      //               ?.map((itm) => itm.value)
                      //               .join(",") === "status"
                      //               ? recommendationStatusColors(status)
                      //               : selectedValues["data_type"]?.value ===
                      //                 "recommendations"
                      //               ? randomColor(index)
                      //               : selectedValues["data_type"]?.value === "sms"
                      //               ? smsColors(status)
                      //               : emailColors(status)
                      //           }
                      //         />
                      //       ))}
                      //     </LineChart>
                      //   </ResponsiveContainer>
                      // ) :
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <LineChart
                            width={500}
                            height={300}
                            data={data.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data[0]
                                  .imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="charge_amount_sum"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        ) : (
                          <LineChart
                            width={500}
                            height={300}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              allowDuplicatedCategory={false}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend iconType="plainline" />
                            {data?.map((itm, idx) =>
                              data?.[idx]?.[0]?.isPreceding ? (
                                <Line
                                  type="monotone"
                                  dataKey={data?.[idx]?.[0]?.label}
                                  stroke={
                                    data?.[idx]?.[0]?.category === "reports"
                                      ? "#FEC400"
                                      : data?.[idx]?.[0]?.category ===
                                        "recommendations"
                                      ? "#6FCF97"
                                      : data?.[idx]?.[0]?.category === "sms"
                                      ? "#00AEEF"
                                      : data?.[idx]?.[0]?.category === "emails"
                                      ? "#FF5F65"
                                      : data?.[idx]?.[0]?.category === "notes"
                                      ? "#9B64C6"
                                      : ""
                                  }
                                  data={itm?.map((item) => ({
                                    date: item.date,
                                    [item.label]: item?.data?.[0].id__count,
                                  }))}
                                  strokeDasharray="10 10"
                                  dot={false}
                                />
                              ) : (
                                <Line
                                  type="monotone"
                                  dataKey={data?.[idx]?.[0]?.label}
                                  stroke={
                                    data?.[idx]?.[0]?.category === "reports"
                                      ? "#FEC400"
                                      : data?.[idx]?.[0]?.category ===
                                        "recommendations"
                                      ? "#6FCF97"
                                      : data?.[idx]?.[0]?.category === "sms"
                                      ? "#00AEEF"
                                      : data?.[idx]?.[0]?.category === "emails"
                                      ? "#FF5F65"
                                      : data?.[idx]?.[0]?.category === "notes"
                                      ? "#9B64C6"
                                      : ""
                                  }
                                  activeDot={{ r: 8 }}
                                  data={itm?.map((item) => ({
                                    date: item.date,
                                    [item.label]: item.data[0].id__count,
                                  }))}
                                />
                              )
                            )}
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    ) : selectedChart?.selectedValues["data_type"]?.value !==
                        "reports" &&
                      selectedChart?.selectedValues["data_type"]?.value !==
                        "notes" ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          width={500}
                          height={300}
                          data={flattenData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />

                          {uniqueStatuses.map((status, index) => (
                            <Line
                              key={index}
                              dataKey={status}
                              name={status}
                              stroke={
                                selectedChart?.selectedValues["data_type"]
                                  ?.value === "recommendations" &&
                                selectedChart?.selectedValues["group_by"]
                                  ?.map((itm) => itm?.value)
                                  .join(",") === "status"
                                  ? recommendationStatusColors(status)
                                  : selectedChart?.selectedValues["data_type"]
                                      ?.value === "recommendations"
                                  ? randomColor(index)
                                  : selectedChart?.selectedValues["data_type"]
                                      ?.value === "sms"
                                  ? smsColors(status)
                                  : emailColors(status)
                              }
                            />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        {selectedChart?.selectedValues["data_subset"]?.value ===
                        "charge" ? (
                          <LineChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              charge_amount_sum:
                                item.data?.[0]
                                  ?.imagine_metadata__charge_amount__sum,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
                              formatter={(value) =>
                                `$${value?.toLocaleString("en-US")}`
                              }
                            />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="charge_amount_sum"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        ) : (
                          <LineChart
                            width={500}
                            height={300}
                            data={data?.map((item) => ({
                              date: item.date,
                              id__count: item.data?.[0]?.id__count,
                            }))}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="id__count"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        )}
                      </ResponsiveContainer>
                    )
                  ) : selectedChart?.selectedValues["chart_type"]?.value ===
                    "pie" ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={600} height={600}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={180}
                          fill="#8884d8"
                          dataKey="id__count"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={legendWrapperStyle}
                          iconType={"circle"}
                          content={renderLegend}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={600} height={600}>
                        <Pie
                          activeIndex={activeIndex}
                          activeShape={renderActiveShape}
                          dataKey="id__count"
                          startAngle={180}
                          endAngle={0}
                          data={data}
                          cy={240}
                          cx={"50%"}
                          outerRadius={220}
                          innerRadius={50}
                          fill="#8884d8"
                          label={renderCustomizedLabel}
                          labelLine={false}
                          onMouseEnter={onPieEnter}
                          animationDuration={0}
                          strokeWidth={0}
                        >
                          {data?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}-${activeIndex}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend
                          iconSize={10}
                          width={120}
                          height={140}
                          layout="vertical"
                          verticalAlign="middle"
                          wrapperStyle={legendWrapperStyle}
                          iconType={"circle"}
                          content={renderLegend}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
                     <StyledCsvBtn
                     data={prepareCSVData()}
                     filename={`${selectedChart?.label}.csv`}
                     target="_blank"
                     >
                     Download as CSV
                    </StyledCsvBtn>

                {(selectedChart?.selectedValues["chart_type"]?.value ===
                  "bar" ||
                  selectedChart?.selectedValues["chart_type"]?.value ===
                    "line") &&
                  selectedChart?.selectedValues["flow_type"]?.value ===
                    "compare" && (
                    <StyledTableContainer
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "1px solid #DFDFDF",
                        borderRadius: "8px",
                      }}
                    >
                      {data.map((itm, idx) => (
                        <table style={{marginBottom:'0px'}}>
                          <thead>
                            <tr>
                              <th style={{ width: "300px", padding:'10px' }}>
                                {data?.[idx]?.[0]?.label}
                              </th>
                              {itm?.map((item, index) => (
                                <th style={{ padding:'10px' }} key={index}>
                                  {" "}
                                  {data?.[idx]?.[0]?.isPreceding
                                    ? item.prevDate
                                    : item.date}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ padding:'10px' }}>
                                {" "}
                                {selectedChart?.selectedValues["data_subset"]
                                  ?.value === "charge"
                                  ? "Revenue"
                                  : "Count"}
                              </td>
                              {itm?.map((item, index) => (
                                <td style={{ padding:'10px' }} key={index}>
                                  {selectedChart?.selectedValues["data_subset"]
                                    ?.value === "charge"
                                    ? `$${
                                        item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString(
                                          "en-US"
                                        ) || 0
                                      }`
                                    : item.data?.[0]?.id__count || 0}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </StyledTableContainer>
                  )}

                {(selectedChart?.selectedValues["chart_type"]?.value ===
                  "bar" ||
                  selectedChart?.selectedValues["chart_type"]?.value ===
                    "line") &&
                  selectedChart?.selectedValues["flow_type"]?.value ===
                    "individual" &&
                  (selectedChart?.selectedValues["data_type"]?.value ===
                    "reports" ||
                  selectedChart?.selectedValues["data_type"]?.value ===
                    "notes" ? (
                    <StyledTableContainer
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "1px solid #DFDFDF",
                        borderRadius: "8px",
                      }}
                    >
                      <table  style={{marginBottom:'0px'}}>
                        <thead>
                          <tr>
                            <th style={{ padding:'10px' }}>Reports</th>
                            {data?.map((item, index) => (
                              <th style={{  padding:'10px' }} key={index}>{item.date}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding:'10px' }}>
                              {" "}
                              {selectedChart?.selectedValues["data_subset"]
                                ?.value === "charge"
                                ? "Revenue"
                                : "Count"}
                            </td>
                            {data?.map((item, index) => (
                              <td style={{ padding:'10px' }} key={index}>
                                {selectedChart?.selectedValues["data_subset"]
                                  ?.value === "charge"
                                  ? `$${
                                      item.data?.[0]?.imagine_metadata__charge_amount__sum?.toLocaleString(
                                        "en-US"
                                      ) || 0
                                    }`
                                  : item.data?.[0]?.id__count || 0}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </StyledTableContainer>
                  ) : (
                    <StyledTableContainer
                      style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "1px solid #DFDFDF",
                        borderRadius: "8px",
                      }}
                    >
                      <table  style={{marginBottom:'0px'}}>
                        <thead>
                          <tr>
                            <th style={{ padding:'10px' }}>Status</th>
                            {flattenData?.map((item, index) => (
                              <th style={{ padding:'10px' }} key={index}>{item.date}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {uniqueStatuses.map((status, statusIndex) => (
                            <tr key={statusIndex}>
                              <td style={{ padding:'10px' }}>{status}</td>
                              {flattenData?.map((item, index) => (
                                <td style={{ padding:'10px' }} key={index}>{item[status] || 0}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </StyledTableContainer>
                  ))}

                  {
                    (selectedChart?.selectedValues["chart_type"]?.value ===
                      "pie" ||
                      selectedChart?.selectedValues["chart_type"]?.value ===
                        "gauge")&&    <StyledTableContainer
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      border: "1px solid #DFDFDF",
                      borderRadius: "8px",
                    }}
                  >
                    <table  style={{marginBottom:'0px'}}>
                      <thead>
                        <tr>
                          <th style={{ padding:'10px' }}>Status</th>
                          <th style={{ padding:'10px' }}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((status, statusIndex) => (
                          <tr key={statusIndex}>
                            <td style={{ padding:'10px' }}>{ `${
                            selectedChart?.selectedValues["data_type"]?.value ===
                            "radiologists" ||
                            selectedChart?.selectedValues["data_type"]?.value ===
                            "referrers"
                            ? status["status"].replaceAll("_", " ")
                            : status[
                            selectedChart?.selectedValues["data_subset"]?.value
                           ]
                          }`}</td>

                            <td style={{ padding:'10px' }}>{status?.id__count}</td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </StyledTableContainer>
                  }
                    
              </>
            )
          )}
        </div>
      )}
      {editing.visible && (
        <EditModal
          selectedValues={editing.data}
          opened={editing.visible}
          onClose={() => setEditing({ visible: false, data: {} })}
          setCustomCharts={setCustomCharts}
          setNextPage={setNextPage}
        />
      )}
    </Container>
  );
};

export default CustomCharts;
